import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import createNotification from '../../common/Notifications';
import rf from '../../requests/requests';
import {FastField, Field, Form, Formik} from 'formik';
import {Button} from '@material-ui/core';
import {BASE_API_URL} from '../../requests/requests';
import {patch, post} from 'axios';
import * as Yup from 'yup';

const PriceSchema = Yup.object().shape({
  price: Yup.string()
    .required('入力は必須です'),
  minute: Yup.string()
    .required('入力は必須です'),
});

class PricesCreateEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreate: !props.id,
      loading: false,
      initParams: {
        id: props.id,
        price: '',
        minute: '',
      }
    };
  }

  componentDidMount() {
    if(!this.state.isCreate){
      this.getPrice();
    }
  }

  getPrice(){
    rf.get(`/admin/prices/${this.state.initParams.id}`)
      .then(res => {
        res.data.price = parseInt(res.data.price).toString();
        res.data.minute = res.data.minute.toString();
        this.setState(state => ({
          initParams: res.data,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(()=> {
        this.setState({loading: false})
      });
  }

  validatePrice(value){
    if(!/^[0-9]+$/i.test(value)) return '数字で入力してください';
    if(parseInt(value) < 1 || parseInt(value) > 99999) return '1〜99,999の範囲で入力してください';
    return '';
  }

  conFirmation = (values, actions) => {
    this.setState({loading: true})
    const params = {
      price: parseInt(values.price),
      minute: parseInt(values.minute),
    };
    if (!this.state.isCreate) {
      this.editPrice(params, actions);
    } else {
      this.createPrice(params, actions);
    }
  };

  createPrice = (params, actions) => {
    const config = {
      headers: JSON.parse(localStorage.getItem('headers_request'))
    };
    const url = `${BASE_API_URL}/admin/prices`;
    const formData = new FormData();

    formData.append('price[price]', params.price);
    formData.append('price[minute]', params.minute);

    post(url, formData, config)
      .then(res => {
        this.props.history.push('/prices');
        actions.setSubmitting(false);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
        actions.setSubmitting(false);
      })
      .finally(()=> {
        this.setState({loading: false})
      });
  };

  editPrice = (params, actions) => {
    const formData = new FormData();
    formData.append('price[price]', params.price);
    formData.append('price[minute]', params.minute);

    const url = `${BASE_API_URL}/admin/prices/${this.state.initParams.id}`;
    const config = {headers: JSON.parse(localStorage.getItem('headers_request'))};
    patch(url, formData, config)
      .then(res => {
        this.props.history.push('/prices');
        actions.setSubmitting(false);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
        actions.setSubmitting(false);
      });
  };

  render() {
    return (
      <MyContent onCreate={true} onBack={true} name='prices' header='価格一覧' contentCreate='価格を登録する'>
        <Fragment>
          <Formik
            initialValues={this.state.initParams}
            onSubmit={this.conFirmation}
            validationSchema={PriceSchema}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isValidating, isValid }) => (
              <Form className="formik_prices_create">

                <div className="group_input_name clearfix">
                  <span className="title">価格 {errors.price && touched.price && <span className='error'>{errors.price}</span>}</span>
                  <FastField className="input" type='text' name='price' placeholder="価格" validate={this.validatePrice}/>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">タイプ {errors.minute && touched.minute && <span className='error'>{errors.minute}</span>}</span>
                  <Field as="select" name="minute">
                      <option value=''></option>
                      <option value={60}>60分</option>
                      <option value={90}>90分</option>
                  </Field>
                </div>
                <Button variant="contained" className="button-save" disabled={this.state.loading} type="submit">保存する</Button>
              </Form>
            )}
          </Formik>
        </Fragment>
      </MyContent>
    );
  };
}

export default PricesCreateEdit;
