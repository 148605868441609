import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import rf from '../../requests/requests';
import createNotification from '../../common/Notifications';
import CircularProgress from '@material-ui/core/CircularProgress';

import './style.scss';
import ReservationsTable from "./ReservationsTable";

class ReservationsList extends Component {
   constructor(props) {
    super(props);
    this.state = {
      reservations: [],
      pagination: {
        page: null,
        total: null,
      },
      page: 1,
      confirmationVisible: false,
      confirmationDelete: false,
      reservations_id: null,
      reservations_status: null,
      loading: false,
    };

    this.onChangeReservationsStatus = this.onChangeReservationsStatus.bind(this);
    this.changeReservationsStatus = this.changeReservationsStatus.bind(this);
    this.onUpdateMemo = this.onUpdateMemo.bind(this);
    this.deleteReservation = this.deleteReservation.bind(this);
    this.onDeleteReservation = this.onDeleteReservation.bind(this);
    this.closeChangeReservationsModal = this.closeChangeReservationsModal.bind(this);
    this.handleChangePagination = this.handleChangePagination.bind(this);

  }

  componentDidMount() {
    this.getDataList();
  }

  getDataList = async (params) => {
    await rf.get(`/admin/reservations`, { params: params })
      .then(res => {
        if(!window._.isEmpty(res.data.reservations) || this.state.page === 1){
          this.setState(state => ({
            reservations: res.data.reservations,
            pagination: res.data.meta.pagination,
            page: res.data.meta.pagination.page,
          }));
        }else{
          this.getDataList(window._.assign(params,{page: this.state.page - 1}));
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  };

  paginationReservations = () => {
    const total = this.state.pagination.total;

    if(total >= 10) {
      return (
        <div style={{margin: '30px 0px', textAlign: 'center'}}>
          <Pagination className="pagination" count={Math.ceil(total / 10)} page={this.state.page} onChange={this.handleChangePagination} variant="outlined" shape="rounded" />
          <Typography style={{marginTop: '16px'}} className="page_of">{this.state.page} /  {(total / 10) < 1 ? '1' : Math.ceil(total / 10)}</Typography>
        </div>
      )
    }
  }


  handleChangePagination(event, value) {
    this.setState({ page: value});
    const params = {page: value};
    this.getDataList(params);
  };

  deleteReservation(id) {
    this.setState(state => ({
      confirmationDelete: true,
      reservations_id: id,
    }));
  }

  changeReservationsStatus(event, row) {
    this.setState(state => ({
      confirmationVisible: true,
      reservations_id: row.id,
      reservations_status: Number(event.target.value),
    }));
  }

  async onChangeReservationsStatus() {
    const params = {
      reservations_status_id: this.state.reservations_status
    }
    this.setState({ loading: true});
    await rf.patch(`/admin/reservations/${this.state.reservations_id}`, { reservation: params })
      .then(async res => {
        const params = {page: this.state.page};
        await this.getDataList(params);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(()=> {
        this.setState({loading: false});
        this.closeChangeReservationsModal()
      })
  }

  async onUpdateMemo(reservations_id, note) {
    const params = {memo: note}
    await rf.patch(`/admin/reservations/${reservations_id}/update_memo`, { reservation: params })
      .then(async res => {
        const params = {page: this.state.page};
        await this.getDataList(params);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0] && error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  async onDeleteReservation(){
    this.setState({ loading: true});
    await rf.delete(`/admin/reservations/${this.state.reservations_id}`)
      .then(async res => {
        await this.getDataList({page: this.state.page});
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(()=> {
        this.setState({ loading: false});
        this.closeChangeReservationsModal();
      })
  }
  
  closeChangeReservationsModal(event) {
    this.setState(state => ({
      confirmationVisible: false,
      confirmationDelete: false,
    }));
  }

  render() {
    return (
      <MyContent onCreate={true} name='reservations' header='予約一覧' contentCreate='新規予約登録'>
        <Fragment>
          <ReservationsTable
            reservations={this.state.reservations}
            changeReservationsStatus={this.changeReservationsStatus}
            onUpdateMemo={this.onUpdateMemo}
            deleteReservation={this.deleteReservation}
          />
          {this.paginationReservations()}

          <Dialog
            open={this.state.confirmationVisible || this.state.confirmationDelete}
            onClose={this.closeChangeReservationsModal}
            className="dialog_confirm"
          >
            <DialogTitle>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.state.confirmationVisible ? '予約状態を変更しても良いですか？' : '本当に削除しますか？取り消し出来ません。'}
              </DialogContentText>
            </DialogContent>
            {(this.state.confirmationVisible || this.state.confirmationDelete) &&
              <DialogActions className="group_botton">
                <Button color="primary" onClick={this.closeChangeReservationsModal} variant="outlined">
                  { this.state.confirmationVisible ? 'キャンセル' : 'いいえ'}
                </Button>
                {this.state.loading ?
                  <Button color="secondary" variant="outlined"><CircularProgress className="icon_loadding" color={'inherit'} size={20}/></Button>
                  :
                  <Button color="secondary" onClick={() => {this.state.confirmationVisible ? this.onChangeReservationsStatus() : this.onDeleteReservation()}} variant="outlined">
                    はい
                  </Button>
                }
              </DialogActions>}
          </Dialog>
        </Fragment>
      </MyContent>
    );
  };
}

export default ReservationsList;
