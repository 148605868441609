import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import rf from '../../requests/requests';
import createNotification from '../../common/Notifications';
import PromotionsTable from "./PromotionsTable";
import './style.scss';

class PromotionsList extends Component {
   constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        page: null,
        total: null,
      },
      page: 1,
      coupon_type: props.resource,
      recordId: null,
      dataParams: {},
    };
  }

  componentDidMount() {
    this.getDataList();
  }

  getDataList = (params) => {
    rf.get(`/admin/${this.state.coupon_type}`, { params: params })
      .then(res => {
        this.setState(state => ({
          data: res.data[this.state.coupon_type],
          pagination: res.data.meta.pagination,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0] && error.response.data.errors[0].message;
        createNotification(message);
      });
  };

  pagination = () => {
    const total = this.state.pagination.total;
    if(total >= 10) {
      return (
        <div style={{margin: '30px 0px', textAlign: 'center'}}>
          <Pagination className="pagination" count={Math.ceil(total / 10)} page={this.state.page} onChange={this.handleChangePagination} variant="outlined" shape="rounded" />
          <Typography style={{marginTop: '16px'}} className="page_of">{this.state.page} /  {(total / 10) < 1 ? '1' : Math.ceil(total / 10)}</Typography>
        </div>
      )
    }
  };

  handleChangePagination = (event, value) => {
    this.setState({ page: value});
    this.getDataList({ page: value })
  };

  render() {
    return (
      <MyContent 
        onCreate={this.state.coupon_type === "admin_promotions"} 
        name={this.state.coupon_type} 
        header={this.state.coupon_type === "admin_promotions" ? "プロモーションコード一覧" : this.state.coupon_type === "invitation_coupons" ? "招待クーポン利用状況" : "招待貢献クーポン利用状況" } 
        contentCreate={this.state.coupon_type === "admin_promotions" ? "プロモーションコード新規追加" : undefined}
        >
        <Fragment>
          <PromotionsTable data={this.state.data} type={this.state.coupon_type}/>
          {this.pagination()}
        </Fragment>
      </MyContent>
    );
  };
}

export default PromotionsList;
