import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import createNotification from '../../common/Notifications';
import rf from '../../requests/requests';
import {Field, Form, Formik} from 'formik';
import Button from '@material-ui/core/Button';
import {BASE_API_URL} from '../../requests/requests';
import {patch} from 'axios';
import Rating from "@material-ui/lab/Rating";

class ReviewsEdit extends Component {
  constructor(props) {
    super(props);
    const ratingData = this.initRatingData();
    this.state = {
      reviewInfo:{
        id: props.id,
        title: '',
        body: '',
        communication: 0,
        customer_satisfaction: 0,
        symptom_improvement: 0,
        display_name: '',
        status: '',
      },
      ratingData,
      loading: false,
    };
  }

  componentDidMount() {
    this.getReview();
  }

  getReview(){
    rf.get(`/admin/reviews/${this.state.reviewInfo.id}`)
      .then(res => {
        this.setState(state => ({
          reviewInfo: res.data,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }
  
  initRatingData = () => {
    const ratingData = [
      {value: 0, label: '0.0'},
    ];

    let start = 0;
    for (let i = 1; i < 51; i++) {
      start += 0.1;
      ratingData.push({
        value: i % 10 === 0 ? start.toFixed(0) : start.toFixed(1),
        label: start.toFixed(1),
      });
    }

    return ratingData;
  };

  validateTitle(value) {
    let error;
    if (value.length < 1) {
      error = 'Field is required';
    }
    return error;
  }

  validateBody(value) {
    let error;
    if (value.length < 1) {
      error = 'Field is required';
    }
    return error;
  }

  validateDisplayName(value) {
    let error;
    if (value.length < 1) {
      error = 'Field is required';
    }
    return error;
  }

  conFirmation = (values) => {
    this.setState({loading: true})
    this.editReview(values)
  };

  editReview = (params) => {
    const formData = new FormData();
    formData.append('review[title]', params.title);
    formData.append('review[body]', params.body);
    formData.append('review[communication]', params.communication);
    formData.append('review[customer_satisfaction]', params.customer_satisfaction);
    formData.append('review[symptom_improvement]', params.symptom_improvement);
    formData.append('review[display_name]', params.display_name);
    formData.append('review[status]', params.status);

    const url = `${BASE_API_URL}/admin/reviews/${this.state.reviewInfo.id}`;
    const config = {headers: JSON.parse(localStorage.getItem('headers_request'))};
    patch(url, formData, config)
      .then(res => {
        this.props.history.push('/reviews');
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(() => this.setState({loading: false}))

  };

  render() {
    return (
      <MyContent onBack={true} name='reviews' header='レビュー'>
        <Fragment>
          <Formik
            initialValues={{
              title: this.state.reviewInfo.title,
              body: this.state.reviewInfo.body,
              communication: this.state.reviewInfo.communication,
              customer_satisfaction: this.state.reviewInfo.customer_satisfaction,
              symptom_improvement: this.state.reviewInfo.symptom_improvement,
              display_name: this.state.reviewInfo.display_name,
              status: this.state.reviewInfo.status,
            }}
            onSubmit={this.conFirmation}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isValidating, isValid }) => (
              <Form className="formik_reviews_create">
                <div className="group_input_name clearfix">
                  <span className="title">タイトル {errors.title && touched.title && <span className='error'>{errors.title}</span>}</span>
                  <Field className="input" type='text' name='title' placeholder="Title" validate={this.validateTitle}/>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">本文 {errors.body && touched.body && <span className='error'>{errors.body}</span>}</span>
                  <Field style={{width: 500, maxWidth: '100%', fontSize: 14, lineHeight: '20px', padding: '10px 15px'}} rows="3" name='body' component="textarea" placeholder="Body"/>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">接客 {errors.communication && touched.communication && <span className='error'>{errors.communication}</span>}</span>
                  <Rating name="read-only" readOnly value={values.communication} style={{marginRight: 15}} />
                  <Field as="select" name='communication' style={{width: 100}}>
                    {
                      this.state.ratingData.map((item, index) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                      ))
                    }
                  </Field>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">満足度 {errors.customer_satisfaction && touched.customer_satisfaction && <span className='error'>{errors.customer_satisfaction}</span>}</span>
                  <Rating name="read-only" readOnly value={values.customer_satisfaction} style={{marginRight: 15}} />
                  <Field as="select" name='customer_satisfaction' style={{width: 100}}>
                    {
                      this.state.ratingData.map((item, index) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                      ))
                    }
                  </Field>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">技術 {errors.symptom_improvement && touched.symptom_improvement && <span className='error'>{errors.symptom_improvement}</span>}</span>
                  <Rating name="read-only" readOnly value={values.symptom_improvement} style={{marginRight: 15}} />
                  <Field as="select" name='symptom_improvement' style={{width: 100}}>
                    {
                      this.state.ratingData.map((item, index) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                      ))
                    }
                  </Field>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">表示名 {errors.display_name && touched.display_name && <span className='error'>{errors.display_name}</span>}</span>
                  <Field className="input" type='text' name='display_name' placeholder="表示名" validate={this.validateDisplayName }/>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">状態 {errors.status && touched.status && <span className='error'>{errors.status}</span>}</span>
                  <Field as="select" name="status">
                    <option value="pending">Pending</option>
                    <option value="active">Active</option>
                  </Field>
                </div>

                <Button variant="contained" className="button-save" disabled={this.state.loading} type="submit">保存する</Button>
              </Form>
            )}
          </Formik>
        </Fragment>
      </MyContent>
    );
  };
}

export default ReviewsEdit;
