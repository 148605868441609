import React from 'react';
import { Layout, AppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import MyMenu from "./MyMenu";
import MySidebar from "./MySidebar";
import {ActionCableProvider} from 'react-actioncable-provider';
import {SOCKET_ENDPOINT} from "../socket";

const useStyles = makeStyles(theme => ({
    menuItem: {
        color: theme.palette.text.secondary,
    },
    icon: { minWidth: theme.spacing(5) },
}));

const MyAppBar = props => <AppBar {...props} />;

const MainLayout = props => {
  const classes = useStyles();
  return (
    <>
      <ActionCableProvider url={SOCKET_ENDPOINT}>
        <Layout
          {...props}
          appBar={MyAppBar}
          menu={MyMenu}
          sidebar={MySidebar}
          className={classes.layout}
        />
      </ActionCableProvider>
    </>
  );
};
export default MainLayout