import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AppointmentModal from "./AppointmentModal";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function AppointmentContentComponent(model) {
  const data = model.data.reservations.reduce((acc, i) => {
    const prev = acc.filter(a => a.start_at === i.start_at)
    const rest = acc.filter(a => a.start_at !== i.start_at)

    if(prev.length > 0){
      return [...rest, {start_at: i.start_at, child: [...prev[0].child, i]}]
    } else {
      return [...rest, {start_at: i.start_at, child: [i]}]
    }
  }, []);
  const date_start = new Date(model.data.startDate);
  // const hour_start = date_start.getHours();
  // const minute_start = date_start.getMinutes() < 9 ? ("0" + date_start.getMinutes()) : date_start.getMinutes();
  // const date_end = new Date(model.data.endDate);
  // const hour_end = date_end.getHours();
  // const minute_end = date_end.getMinutes() < 9 ? ("0" + date_end.getMinutes()) : date_end.getMinutes();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (event) => {
    setOpen(true);
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setOpen(false);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <div className='showtime-preview'>
        {data.length > 0 &&
        <ul className="list_reservations">
          {data.map((item, index) =>
            <li key={index} style={{top: (Math.abs(new Date(item.start_at) - date_start)/3600000) !== 0 ? ((Math.abs(new Date(item.start_at) - date_start)/3600000)*96 + 5) : (Math.abs(new Date(item.start_at) - date_start)/3600000 + 5)}}>
              <ul className="sub_list">
                {item.child.map((it, index) =>
                <li className={it.reservations_status && it.reservations_status.id === 3 ? 'item-cancel-reservation' : (it.reservations_status.id === 2 ? 'item-done' : 'item-doing')} key={index} style={{ width: (100/item.child.length + '%')}}>
                  <HtmlTooltip
                      placement="left"
                      title={
                        <React.Fragment>
                          <div>予約者名: {it.full_name_kanji_member}</div>
                          <div>ステータス: {it.reservations_status && it.reservations_status.id === 1 ? '予約中' : (it.reservations_status.id === 2 ? '施術完了' : '予約キャンセル')}</div>
                          <div>メール: <a href={it.mail_member}>{it.mail_member}</a></div>
                          <div>電話番号: {it.phone_number_member}</div>
                          <div>開始時間: {it.start_at}</div>
                          <div>終了時間: {it.end_at}</div>
                        </React.Fragment>
                      }
                    >
                      <div className="btn-tooltip" onClick={handleClickOpen}>
                        <div>{it.start_at.slice(11, 16)}</div>
                        <div className="name">{it.full_name_kanji_member}</div>
                        <div className="name">{it.reservations_status && it.reservations_status.id === 1 ? '予約中' : (it.reservations_status.id === 2 ? '施術完了' : '予約キャンセル')}</div>
                      </div>
                    </HtmlTooltip>
                    <AppointmentModal
                      appointItem={it}
                      open={open}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                    />
                </li>
                )}
              </ul>
            </li>
          )}
        </ul>
        }
      </div>
    </>
  )
};

export default AppointmentContentComponent;
