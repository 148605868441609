import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import createNotification from '../../common/Notifications';
import rf from '../../requests/requests';
import {FastField, Form, Formik} from 'formik';
import {Button} from '@material-ui/core';
import {BASE_API_URL} from '../../requests/requests';
import {patch} from 'axios';
import * as Yup from 'yup';
import './style.scss';

const CouponsSettingSchema = Yup.object().shape({
  discount_rate: Yup.string()
    .required('入力は必須です'),
  usable_number_of_times: Yup.string()
    .required('入力は必須です'),
  expired_in: Yup.string()
    .required('入力は必須です'),
});

class CouponsSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      coupon_type: props.resource,
      initParams: {
        discount_rate: '',
        usable_number_of_times: '',
        expired_in: '',
      }
    };
  }

  componentDidMount() {
    this.getCouponSetting();
  }

  getCouponSetting(){
    rf.get(`/admin/${this.state.coupon_type}`)
      .then(res => {
      if(res.data){
        res.data.usable_number_of_times = res.data.usable_number_of_times.toString();
        res.data.expired_in = res.data.expired_in.toString();
        res.data.discount_rate = res.data.discount_rate.toString();
        this.setState(state => ({
          initParams: res.data,
        }));
      }
    })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  validatePercent(value){
    if(!/^[0-9.]+$/i.test(value)) return '数字で入力してください';
    if(!/^(?:\d+)?(?:\.\d{0,1})?$/.test(value)) return '小数点第1位までで入力してください' ;
    if(parseFloat(value) <= 0 || parseFloat(value) >= 100) return '割引率は 1〜99 の間で入力可能です';
    return '';
  }

  validateUsableNumberOfTimes(value){
    if(!/^-?[\d]+(?:e-?\d+)?$/.test(value)) return '残回数は数値での入力が可能です';
    if(parseInt(value) < 0 || parseInt(value) > 1000) return '残り回数は0〜1000の間で入力可能です';
    return '';
  }

  validateExpiredIn(value){
    if(!/^-?[\d]+(?:e-?\d+)?$/.test(value)) return '数字で入力してください';
    if(parseInt(value) < 1 || parseInt(value) > 365) return '使用期限は1〜365日の範囲で入力してください';
    return '';
  }

  conFirmation = (values, actions) => {
    this.setState({loading: true})
    const params = {
      discount_rate: values.discount_rate,
      usable_number_of_times: values.usable_number_of_times,
      expired_in: values.expired_in,
    };
    this.editPromotion(params, actions);
  };

  editPromotion = (params, actions) => {
    const config = {headers: JSON.parse(localStorage.getItem('headers_request'))};
    const formData = new FormData();
    const url = `${BASE_API_URL}/admin/${this.state.coupon_type}`;
    const object = this.state.coupon_type.substring(0, this.state.coupon_type.length - 1)

    formData.append(`${object}[discount_rate]`, parseFloat(params.discount_rate));
    formData.append(`${object}[usable_number_of_times]`, parseInt(params.usable_number_of_times));
    formData.append(`${object}[expired_in]`, parseInt(params.expired_in));

    
    patch(url, formData, config)
      .then(res => {
        this.props.history.push(`/${this.state.coupon_type}`);
        actions.setSubmitting(false);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
        actions.setSubmitting(false);
      })
      .finally(() => this.setState({loading: false}))

  };

  render() {
    return (
      <MyContent name={this.state.coupon_type} header={this.state.coupon_type === 'invitation_default_coupons' ? "招待クーポン管理者設定": "招待貢献クーポン管理者設定"}>
        <Fragment>
          <Formik
            initialValues={this.state.initParams}
            onSubmit={this.conFirmation}
            validationSchema={CouponsSettingSchema}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isValidating, isValid }) => (
              <Form className="formik_promotions_create">

                <div className="group_input_name clearfix">
                  <span className="title">割引率 {errors.discount_rate && touched.discount_rate && <span className='error'>{errors.discount_rate}</span>}</span>
                  <FastField
                    className="input" name='discount_rate' placeholder="割引率" validate={this.validatePercent}
                  />
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">回数 {errors.usable_number_of_times && touched.usable_number_of_times && <span className='error'>{errors.usable_number_of_times}</span>}</span>
                  <FastField
                    className="input" name='usable_number_of_times' placeholder="残回数" validate={this.validateUsableNumberOfTimes}
                  />
                </div>

                <div className="group_input_name clearfix">
                <span className="title">使用期日 {errors.expired_in && touched.expired_in && <span className='error'>{errors.expired_in}</span>}</span>
                  <FastField
                    className="input" name='expired_in' placeholder="使用期日" validate={this.validateExpiredIn}
                  />
                </div>

                <Button variant="contained" className="button-save" disabled={this.state.loading} type="submit">保存する</Button>
              </Form>
            )}
          </Formik>
        </Fragment>
      </MyContent>
    );
  };
}

export default CouponsSetting;
