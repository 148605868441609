import { NotificationManager } from "react-notifications";

const createNotification = (message, type = "error") => {
  const time = 5000;
  switch (type) {
    case "info":
      NotificationManager.info(message, "", time);
      break;
    case "success":
      NotificationManager.success(message, "", time);
      break;
    case "warning":
      NotificationManager.warning(message, "", time);
      break;
    case "error":
      NotificationManager.error(message, "", time);
      break;
    default:
      break;
  }
};
export default createNotification;
