import * as React from 'react';
import MyContent from '../../layout/MyContent';
import createNotification from '../../common/Notifications';
import {EditingState, ViewState} from '@devexpress/dx-react-scheduler';
import * as _ from 'lodash';
import {
  AllDayPanel,
  AppointmentForm,
  Appointments,
  AppointmentTooltip,
  CurrentTimeIndicator,
  DateNavigator,
  DragDropProvider,
  EditRecurrenceMenu,
  MonthView,
  Scheduler,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import {connectProps} from '@devexpress/dx-react-core';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import CalendarToday from '@material-ui/icons/CalendarToday';
import rf from '../../requests/requests';
import AppointmentContentComponent from "./appointment/AppointmentContentComponent";

const containerStyles = theme => ({
  container: {
    width: theme.spacing(68),
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  header: {
    overflow: 'hidden',
    paddingTop: theme.spacing(0.5),
  },
  closeButton: {
    float: 'right',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  picker: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
    width: '50%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  icon: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
});

class AppointmentFormContainerBasic extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shiftId: window.location.href.split('/')[window.location.href.split('/').length -2],
      appointmentChanges: {},
    };

    this.getAppointmentData = () => {
      const { appointmentData } = this.props;
      return appointmentData;
    };
    this.getAppointmentChanges = () => {
      const { appointmentChanges } = this.state;
      return appointmentChanges;
    };

    this.changeAppointment = this.changeAppointment.bind(this);
    this.commitAppointment = this.commitAppointment.bind(this);
  }

  changeAppointment({ field, changes }) {
    const nextChanges = {
      ...this.getAppointmentChanges(),
      [field]: changes,
    };
    this.setState({
      appointmentChanges: nextChanges,
    });
  }

  commitAppointment(type) {
    const { commitChanges } = this.props;
    const appointment = {
      ...this.getAppointmentData(),
      ...this.getAppointmentChanges(),
    };
    if (type === 'deleted') {
      commitChanges({ [type]: appointment.id });
    } else if (type === 'changed') {
      commitChanges({ [type]: { [appointment.id]: appointment } });
    } else {
      commitChanges({ [type]: appointment });
    }
    this.setState({
      appointmentChanges: {},
    });
  }

  render() {
    const {
      classes,
      visible,
      visibleChange,
      appointmentData,
      cancelAppointment,
      target,
      onHide,
    } = this.props;
    const { appointmentChanges } = this.state;

    const displayAppointmentData = {
      ...appointmentData,
      ...appointmentChanges,
    };

    const isNewAppointment = appointmentData.id === undefined;
    const applyChanges = isNewAppointment
      ? () => this.commitAppointment('added')
      : () => this.commitAppointment('changed');

    // const textEditorProps = field => ({
    //   variant: 'outlined',
    //   onChange: ({ target: change }) => this.changeAppointment({
    //     field: [field], changes: change.value,
    //   }),
    //   value: displayAppointmentData[field] || '',
    //   label: field[0].toUpperCase() + field.slice(1),
    //   className: classes.textField,
    // });

    const pickerEditorProps = field => ({
      className: classes.picker,
      // keyboard: true,
      ampm: false,
      value: displayAppointmentData[field],
      onChange: date => this.changeAppointment({
        field: [field], changes: date ? date.toDate() : new Date(displayAppointmentData[field]),
      }),
      inputVariant: 'outlined',
      format: 'MM/DD/YYYY HH:mm',
      onError: () => null,
    });

    const cancelChanges = () => {
      this.setState({
        appointmentChanges: {},
      });
      visibleChange();
      cancelAppointment();
    };

    return (
      <AppointmentForm.Overlay
        visible={visible}
        target={target}
        fullSize
        onHide={onHide}
      >
        <div>
          <div className={classes.header}>
            <IconButton
              className={classes.closeButton}
              onClick={cancelChanges}
            >
              <Close color="action" />
            </IconButton>
          </div>
          <div className={classes.content}>
            <div className={classes.wrapper}>
              <CalendarToday className={classes.icon} color="action" />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  label="開始時間"
                  {...pickerEditorProps('startDate')}
                />
                <KeyboardDateTimePicker
                  label="終了時間"
                  {...pickerEditorProps('endDate')}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={classes.buttonGroup}>
            {/* {!isNewAppointment && (
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  visibleChange();
                  this.commitAppointment('changed');
                }}
              >
                シフト編集
              </Button>
            )} */}
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => {
                visibleChange();
                applyChanges();
              }}
            >
              {isNewAppointment ? '作成する' : '保存'}
            </Button>
          </div>
        </div>
      </AppointmentForm.Overlay>
    );
  }
}

const AppointmentFormContainer = withStyles(containerStyles, { name: 'AppointmentFormContainer' })(AppointmentFormContainerBasic);

const styles = theme => ({
  addButton: {
    position: 'absolute',
    bottom: theme.spacing(1) * 3,
    right: theme.spacing(1) * 4,
  },
});

/* eslint-disable-next-line react/no-multi-comp */
class CalendarCreate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shiftId: props.match.params.id,
      data: [],
      currentDate: new Date(),
      confirmationVisible: false,
      editingFormVisible: false,
      deletedAppointmentId: undefined,
      editingAppointment: undefined,
      previousAppointment: undefined,
      addedAppointment: {},
      startDayHour: 0,
      endDayHour: 24,
      isNewAppointment: false,
      locale: 'ja-JA',
      shifts: [],
      curren_date_table: null,
    };

    this.toggleConfirmationVisible = this.toggleConfirmationVisible.bind(this);
    this.commitDeletedAppointment = this.commitDeletedAppointment.bind(this);
    this.toggleEditingFormVisibility = this.toggleEditingFormVisibility.bind(this);
    this.commitChanges = this.commitChanges.bind(this);
    this.onEditingAppointmentChange = this.onEditingAppointmentChange.bind(this);
    this.onAddedAppointmentChange = this.onAddedAppointmentChange.bind(this);
    this.getPractitionersAdmin = this.getPractitionersAdmin.bind(this);
    this.onChangeDateTable = this.onChangeDateTable.bind(this);
    this.createShirt = this.createShirt.bind(this);
    this.changedShirt = this.changedShirt.bind(this);
    this.deletedShirt = this.deletedShirt.bind(this);
    this.appointmentForm = connectProps(AppointmentFormContainer, () => {
      const {
        editingFormVisible,
        editingAppointment,
        shifts,
        addedAppointment,
        isNewAppointment,
        previousAppointment,
      } = this.state;

      const currentAppointment = shifts
        .filter(appointment => editingAppointment && appointment.id === editingAppointment.id)[0]
        || addedAppointment;
      const cancelAppointment = () => {
        if (isNewAppointment) {
          this.setState({
            editingAppointment: previousAppointment,
            isNewAppointment: false,
          });
        }
      };

      return {
        visible: editingFormVisible,
        appointmentData: currentAppointment,
        commitChanges: this.commitChanges,
        visibleChange: this.toggleEditingFormVisibility,
        onEditingAppointmentChange: this.onEditingAppointmentChange,
        cancelAppointment,
      };
    });
  }

  componentDidUpdate() {
    this.appointmentForm.update();
  }

  componentDidMount() {
    this.getPractitionersAdmin();
  }

  getPractitionersAdmin(value_date) {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var start_day = mm + '/' + dd + '/' + yyyy;
    const params = {
      start_at: value_date ? value_date : start_day,
    }
    rf.get(`/admin/practitioners/${this.state.shiftId}/shifts`, {params: params})
      .then(res => {
        const data_shifts = _.map(res.data, (shift) => {
          return {...shift, ...{'startDate': shift.date_start, 'endDate': shift.date_end }}
        })
        this.setState(state => ({
          shifts: data_shifts,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        message === 'Permission denied' && this.props.history.push('/practitioners');
        createNotification(message);
      });
  }

  onEditingAppointmentChange(editingAppointment) {
    this.setState({ editingAppointment });
  }

  onAddedAppointmentChange(addedAppointment) {
    this.setState({ addedAppointment });
    const { editingAppointment } = this.state;
    if (editingAppointment !== undefined) {
      this.setState({
        previousAppointment: editingAppointment,
      });
    }
    this.setState({ editingAppointment: undefined, isNewAppointment: true });
  }

  setDeletedAppointmentId(id) {
    this.setState({ deletedAppointmentId: id });
  }

  toggleEditingFormVisibility() {
    const { editingFormVisible } = this.state;
    this.setState({
      editingFormVisible: !editingFormVisible,
    });
  }

  toggleConfirmationVisible() {
    const { confirmationVisible } = this.state;
    this.setState({ confirmationVisible: !confirmationVisible });
  }

  commitDeletedAppointment() {
    this.setState((state) => {
      const { data, deletedAppointmentId } = state;
      const nextData = data.filter(appointment => appointment.id !== deletedAppointmentId);

      return { data: nextData, deletedAppointmentId: null };
    });
    this.toggleConfirmationVisible();
  }

  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const params = {
          start_at: added.startDate,
          end_at: added.endDate,
        }
        this.createShirt(params);
      }
      if (changed) {
        this.changedShirt(changed);
      }
      if (deleted !== undefined) {
        this.deletedShirt(deleted)
      }
      return { data, addedAppointment: {} };
    });
  }

  createShirt(params) {
    rf.post(`/admin/practitioners/${this.state.shiftId}/shifts`, {shift: params})
      .then(res => {
        this.getPractitionersAdmin(this.state.curren_date_table);
      })
      .catch(error => {
        this.getPractitionersAdmin(this.state.curren_date_table);
                const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  changedShirt(changed) {
    var params_changed = Object.entries(changed);
    var practitioner_params = params_changed[0][1];
    const params = {
      start_at: new Date(practitioner_params.startDate),
      end_at: new Date(practitioner_params.endDate),
    }
    rf.patch(`/admin/practitioners/${this.state.shiftId}/shifts/${practitioner_params.id}`, {shift: params})
      .then(res => {
        this.getPractitionersAdmin(this.state.curren_date_table);
        const message = res.data && res.data.message;
        createNotification(message, 'success');
      })
      .catch(error => {
        this.getPractitionersAdmin(this.state.curren_date_table);
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  deletedShirt(deleted) {
    rf.delete(`/admin/practitioners/${this.state.shiftId}/shifts/${deleted}`)
      .then(res => {
        this.getPractitionersAdmin(this.state.curren_date_table);
      })
      .catch(error => {
        this.getPractitionersAdmin(this.state.curren_date_table);
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  onChangeDateTable(value) {
    var full_date = new Date(value);
    var dd = String(full_date.getDate()).padStart(2, '0');
    var mm = String(full_date.getMonth() + 1).padStart(2, '0');
    var yyyy = full_date.getFullYear();
    var curren_date = mm + '/' + dd + '/' + yyyy;
    this.setState({curren_date_table: curren_date}, () => {
      this.getPractitionersAdmin(this.state.curren_date_table)
    })
  }

  render() {
    const {
      shifts,
      currentDate,
      confirmationVisible,
      editingFormVisible,
      startDayHour,
      endDayHour,
      locale,
    } = this.state;
    const { classes } = this.props;

    return (
      <MyContent onCreate={true} onBack={true} name='practitioners' header='施術者一覧' contentCreate='施術者を登録する'>
        <Scheduler
          data={shifts}
          height={660}
          locale={locale}
        >
          <ViewState
            onCurrentDateChange={this.onChangeDateTable}
            defaultCurrentDate={currentDate}
          />
          <EditingState
            onCommitChanges={this.commitChanges}
            onEditingAppointmentChange={this.onEditingAppointmentChange}
            onAddedAppointmentChange={this.onAddedAppointmentChange}
          />
          <WeekView
            startDayHour={startDayHour}
            endDayHour={endDayHour}
          />
          <MonthView />
          <AllDayPanel />
          <EditRecurrenceMenu />
          <Appointments appointmentContentComponent={AppointmentContentComponent}/>
          <AppointmentTooltip
            showOpenButton
            showCloseButton
            showDeleteButton
          />
          <Toolbar />
          <DateNavigator />
          <ViewSwitcher />
          <AppointmentForm
            overlayComponent={this.appointmentForm}
            visible={editingFormVisible}
            onVisibilityChange={this.toggleEditingFormVisibility}
          />
          <DragDropProvider />
          <CurrentTimeIndicator
              shadePreviousCells={true}
              shadePreviousAppointments={false}
            />
        </Scheduler>

        <Dialog
          open={confirmationVisible}
          onClose={this.cancelDelete}
        >
          <DialogTitle>
            Delete Appointment
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this appointment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleConfirmationVisible} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={this.commitDeletedAppointment} color="secondary" variant="outlined">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Fab
          color="secondary"
          className={classes.addButton}
          onClick={() => {
            this.setState({ editingFormVisible: true });
            this.onEditingAppointmentChange(undefined);
            this.onAddedAppointmentChange({
              startDate: new Date(currentDate).setHours(startDayHour),
              endDate: new Date(currentDate).setHours(startDayHour + 1),
            });
          }}
        >
          <AddIcon />
        </Fab>
      </MyContent>
    );
  }
}

export default withStyles(styles, { name: 'EditingCalendarCreate', theme: true })(CalendarCreate);
