
export const formatDateTime = (dateTime, format = 'm/d/Y H:i') => {
  if (!dateTime) return new Date().toISOString();

  let formattedDateTime = new Date(dateTime);
  let year, month, date, hours, minutes;

  switch (format) {
    case 'YYYY-MM-DD':
      formattedDateTime = formattedDateTime.toISOString().slice(0, 10);
      break;
    case 'YYYY-MM-DD H:i':
      formattedDateTime = formattedDateTime.toISOString().slice(0, 16).replace('T', ' ');
      break;
    case 'YYYY-MM-DD H:i:s':
      formattedDateTime = formattedDateTime.toISOString().slice(0, 19);
      break;

    case 'm/d/Y H:i':
      year = formattedDateTime.getFullYear();
      month = ('0' + (formattedDateTime.getMonth() + 1)).slice(-2);
      date = ('0' + formattedDateTime.getDate()).slice(-2);
      hours = ('0' + formattedDateTime.getHours()).slice(-2);
      minutes = ('0' + formattedDateTime.getMinutes()).slice(-2);
      formattedDateTime = `${month}/${date}/${year} ${hours}:${minutes}`;
      break;
    case 'm/d/Y':
      year = formattedDateTime.getFullYear();
      month = ('0' + (formattedDateTime.getMonth() + 1)).slice(-2);
      date = ('0' + formattedDateTime.getUTCDate()).slice(-2);
      hours = ('0' + formattedDateTime.getHours()).slice(-2);
      minutes = ('0' + formattedDateTime.getMinutes()).slice(-2);

      formattedDateTime = `${month}/${date}/${year}`;
      break;
    default:
  }

  // console.log('formattedDateTime===', formattedDateTime);

  return formattedDateTime;
};

