import ListAltIcon from '@material-ui/icons/ListAlt';
import ReservationsList from './ReservationsList';
import ReservationsCreateEdit from './ReservationsCreateEdit';

const Reservations = {
    list: ReservationsList,
    create: ReservationsCreateEdit,
    icon: ListAltIcon,
}
export default Reservations;
