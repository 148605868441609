import React, { Component, Fragment } from 'react';
import MyContent from '../../layout/MyContent';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import rf from '../../requests/requests';
import createNotification from '../../common/Notifications';
import PractitionersTable from "./PractitionersTable";
import './style.scss';

class PractitionersList extends Component {
   constructor(props) {
    super(props);
    this.state = {
      practitioners: [],
      pagination: {
        page: null,
        total: null,
      },
      page: 1,
      practitioner_id: null,
      practitionerPrams: {},
    };

    this.getPractitioners = this.getPractitioners.bind(this);
    this.handleChangePagination = this.handleChangePagination.bind(this);
  }

  componentDidMount() {
    this.getPractitioners();
  }

  getPractitioners(params) {
    rf.get(`/admin/practitioners`, { params: params })
      .then(res => {
        this.setState(state => ({
          practitioners: res.data.practitioners,
          pagination: res.data.meta.pagination,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  paginationPractitioners() {
    const total = this.state.pagination.total;

    if(total >= 10) {
      return (
        <div style={{margin: '30px 0px', textAlign: 'center'}}>
          <Pagination className="pagination" count={Math.ceil(total / 10)} page={this.state.page} onChange={this.handleChangePagination} variant="outlined" shape="rounded" />
          <Typography style={{marginTop: '16px'}} className="page_of">{this.state.page} /  {(total / 10) < 1 ? '1' : Math.ceil(total / 10)}</Typography>
        </div>
      )
    }
  }

  handleChangePagination(event, value) {
    this.setState({ page: value});
    const params = {
      page: value
    }
    this.getPractitioners(params)
  };

  onTimeReservedChange = (data) => {
    console.log('data======>', data);
  };

  render() {
    return (
      <MyContent onCreate={true} name='practitioners' header='施術者一覧' contentCreate='施術者を登録する'>
        <Fragment>
          <PractitionersTable
            practitioners={this.state.practitioners}
          />
          {this.paginationPractitioners()}
        </Fragment>
      </MyContent>
    );
  };
}

export default PractitionersList;
