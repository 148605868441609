import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import createNotification from '../../common/Notifications';
import rf from '../../requests/requests';
import {FastField, Field, Form, Formik} from 'formik';
import {Button} from '@material-ui/core';
import {BASE_API_URL} from '../../requests/requests';
import {patch, post} from 'axios';
import deLocale from "date-fns/locale/ja";
import {MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';
import * as Yup from 'yup';

const PromotionSchema = Yup.object().shape({
  discount_rate: Yup.string()
    .required('入力は必須です'),
  usable_number_of_times: Yup.string()
    .required('入力は必須です'),
  expired_at: Yup.string().nullable()
    .required('使用期日を設定してください'),
  // scope: Yup.string()
  // .required('入力は必須です'),
});

const formatDate = (date) => {
  const d = new Date(date);
  return d.toLocaleDateString("en", {year: "numeric", month: "2-digit", day: "numeric"});
}
class PromotionsCreateEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreate: !props.id,
      loading: false,
      coupon_type: props.resource,
      initParams: {
        id: props.id,
        title: '',
        discount_code: '',
        discount_rate: '',
        scope: '',
        usable_number_of_times: '',
        expired_at: new Date(),
      }
    };
    // this.validateDiscountCode = this.validateDiscountCode.bind(this);
  }

  componentDidMount() {
    if(!this.state.isCreate){
      this.getPromotion();
    }else{
      this.generateDiscountCode();
    }
  }

  generateDiscountCode() {
    rf.get(`/admin/admin_promotions/get_discount_code`)
      .then(res => {
        const discount_code = res.data.discount_code;
        this.setState(state => ({
          initParams: {...state.initParams, discount_code: discount_code},
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  getPromotion(){
    rf.get(`/admin/${this.state.coupon_type}/${this.state.initParams.id}`)
      .then(res => {
        res.data.discount_rate = res.data.discount_rate.toString();
        res.data.usable_number_of_times = res.data.usable_number_of_times.toString();
        if(this.state.coupon_type === 'admin_promotions' && res.data.title === null) res.data.title = '';
        if(this.state.coupon_type === 'admin_promotions' && res.data.scope === null) res.data.scope = '';
        this.setState(state => ({
          initParams: res.data,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  // validateDiscountCode(value){
  //   if(this.state.coupon_type === 'invitation_coupons' || this.state.coupon_type === 'contributed_coupons'){
  //     if(!/^[a-z]{2}[0-9]{4}$/.test(value)) return 'wrong format!';
  //   }else{
  //     if(!/^PROMO[0-9]{2}$/.test(value)) return 'wrong format!';
  //   }
  //   return '';
  // }

  validateTitle(value){
    if(value.trim().length < 1) return '入力は必須です';
    if(value.length > 128) return '128文字以内で入力してください'
    return '';
  }

  validatePercent(value){
    if(!/^[0-9.]+$/i.test(value)) return '数字で入力してください';
    if(!/^(?:\d+)?(?:\.\d{0,1})?$/.test(value)) return '小数点第1位までで入力してください' ;
    if(parseFloat(value) <= 0 || parseFloat(value) >= 100) return '割引率は 1〜99 の間で入力可能です';
    return '';
  }

  validateUsableNumberOfTimes(value){
    if(!/^-?[\d]+(?:e-?\d+)?$/.test(value)) return '残回数は数値での入力が可能です';
    if(parseInt(value) < 0 || parseInt(value) > 1000) return '残り回数は0〜1000の間で入力可能です';
    return '';
  }

  validateScope(value){
    if(value.length === 0) return '入力は必須です';
    return '';
  }

  validateExpiredAt(value){
    if(formatDate(value) === 'Invalid Date') return '使用期日を正しいフォーマットで入力してください。';
    if((new Date().getTime()) - (new Date(value).getTime()) > 86400000) return '使用期日は過去日、当日、翌日の日付はご利用頂けません。';
    return '';
  }

  conFirmation = (values, actions) => {
    this.setState({loading: true})
    const params = {
      title: values.title,
      discount_code: values.discount_code,
      discount_rate: values.discount_rate,
      usable_number_of_times: values.usable_number_of_times,
      scope: values.scope,
      expired_at: formatDate(values.expired_at),
    };
    if (!this.state.isCreate) {
      this.editPromotion(params, actions);
    } else {
      this.createPromotion(params, actions);
    }
  };

  createPromotion = (params, actions) => {
    const config = {
      headers: JSON.parse(localStorage.getItem('headers_request'))
    };
    const url = `${BASE_API_URL}/admin/admin_promotions`;
    const formData = new FormData();

    formData.append('admin_promotion[title]', params.title);
    formData.append('admin_promotion[discount_code]', params.discount_code);
    formData.append('admin_promotion[discount_rate]', parseFloat(params.discount_rate));
    formData.append('admin_promotion[usable_number_of_times]', parseInt(params.usable_number_of_times));
    formData.append('admin_promotion[scope]', params.scope);
    formData.append('admin_promotion[expired_at]', params.expired_at);

    post(url, formData, config)
      .then(res => {
        this.props.history.push('/admin_promotions');
        actions.setSubmitting(false);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0] && error.response.data.errors[0].message;
        createNotification(message);
        actions.setSubmitting(false);
      })
      .finally(() => this.setState({loading: false}))

  };

  editPromotion = (params, actions) => {
    
    const object = this.state.coupon_type.substring(0, this.state.coupon_type.length - 1)
    const formData = new FormData();
    this.state.coupon_type === "admin_promotions" && formData.append(`${object}[discount_code]`, params.discount_code);
    this.state.coupon_type === "admin_promotions" && formData.append(`${object}[title]`, params.title);
    formData.append(`${object}[discount_rate]`, parseFloat(params.discount_rate));
    formData.append(`${object}[usable_number_of_times]`, parseInt(params.usable_number_of_times));
    this.state.coupon_type === "admin_promotions" && formData.append(`${object}[scope]`, params.scope);
    formData.append(`${object}[expired_at]`, params.expired_at);

    const url = `${BASE_API_URL}/admin/${this.state.coupon_type}/${this.state.initParams.id}`;
    const config = {headers: JSON.parse(localStorage.getItem('headers_request'))};
    patch(url, formData, config)
      .then(res => {
        this.props.history.push(`/${this.state.coupon_type}`);
        actions.setSubmitting(false);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
        actions.setSubmitting(false);
      })
      .finally(() => this.setState({loading: false}))

  };

  render() {
    return (
      <MyContent 
        onBack={true}
        onCreate={this.state.coupon_type === "admin_promotions"} 
        name={this.state.coupon_type} 
        header={this.state.coupon_type === "admin_promotions" ? "プロモーションコード一覧" : this.state.coupon_type === "invitation_coupons" ? "招待クーポン利用状況" : "招待貢献クーポン利用状況" } 
        contentCreate={this.state.coupon_type === "admin_promotions" ? "プロモーションコード新規追加" : undefined}
        >
        <Fragment>
          <Formik
            initialValues={this.state.initParams}
            onSubmit={this.conFirmation}
            validationSchema={PromotionSchema}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isValidating, isValid }) => (
              <Form className="formik_promotions_create">
                {this.state.coupon_type === "admin_promotions" && <div className="group_input_name clearfix">
                  <span className="title">タイトル {errors.title && touched.title && <span className='error'>{errors.title}</span>}</span>
                  <FastField className="input" type='text' name='title' placeholder="タイトル" validate={this.validateTitle}/>
                </div>}

                {this.state.coupon_type === "admin_promotions" && this.state.isCreate && <div className="group_input_name clearfix">
                  <span className="title">クーポンコード {errors.discount_code && touched.discount_code && <span className='error'>{errors.discount_code}</span>}</span>
                  <FastField disabled className="input" type='text' name='discount_code' placeholder="クーポンコード"/>
                </div>}

                <div className="group_input_name clearfix">
                  <span className="title">割引率 {errors.discount_rate && touched.discount_rate && <span className='error'>{errors.discount_rate}</span>}</span>
                  <FastField
                    className="input" name='discount_rate' placeholder="割引率" validate={this.validatePercent}
                  />
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">残回数 {errors.usable_number_of_times && touched.usable_number_of_times && <span className='error'>{errors.usable_number_of_times}</span>}</span>
                  <FastField
                    className="input" name='usable_number_of_times' placeholder="残回数" validate={this.validateUsableNumberOfTimes}
                  />
                </div>
                
                {this.state.coupon_type === "admin_promotions" && <div className="group_input_name clearfix">
                  <span className="title">Scope {errors.scope && touched.scope && <span className='error'>{errors.scope}</span>}</span>
                  <FastField as="select" name="scope" validate={this.validateScope}>
                    <option value=""></option>
                    <option value="new_user">新規ユーザーのみ</option>
                    <option value="old_user">既存ユーザーのみ</option>
                  </FastField>
                </div>}
                <div className="group_input_name clearfix">
                  <span className="title">使用期日</span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                      <Field
                        component={KeyboardDatePicker}
                        name="expired_at"
                        className="datetime-control"
                        inputVariant="outlined"
                        format="yyyy/MM/dd"
                        disablePast
                        invalidDateMessage='使用期日を正しいフォーマットで入力してください。'
                        minDateMessage='使用期日は過去日、当日、翌日の日付はご利用頂けません。'
                        validate={this.validateExpiredAt}
                      />
                    </MuiPickersUtilsProvider>
                </div>

                <Button variant="contained" className="button-save" disabled={this.state.loading} type="submit">保存する</Button>
              </Form>
            )}
          </Formik>
        </Fragment>
      </MyContent>
    );
  };
}

export default PromotionsCreateEdit;
