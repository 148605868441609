import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const tooltipPopupStyles = makeStyles(theme => ({
  tooltipArea: {
    margin: 10,
    minHeight: 120,
  },
  showPopup: {
    display: 'none',
  },
  tooltipPopupContainer: {
    margin: 10,
    fontSize: 16,
  },
  dataItem: {
  },
  [theme.breakpoints.down("sm")]: {
    showPopup: {
      display: 'block',
    },
    tooltipPopupContainer: {
      margin: 7,
      fontSize: 13,
    },
  },
}));

export default function AppointmentModal(props) {
  const classes = tooltipPopupStyles();
  const {appointItem, open, handleClose} = props;
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className={classes.showPopup}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Detail
        </DialogTitle>
        <DialogContent dividers>

          <Grid container item spacing={1} xs={12} className={classes.tooltipPopupContainer}>
            <Grid container className={classes.dataItem}>
              <Grid item xs={12}>予約者名: {appointItem.full_name_kanji_member}</Grid>
            </Grid>

            <Grid container className={classes.dataItem}>
              <Grid item xs={12}>
                ステータス: {appointItem.reservations_status && appointItem.reservations_status.id === 1 ? '予約中' : (appointItem.reservations_status.id === 2 ? '施術完了' : '予約キャンセル')}
              </Grid>
            </Grid>

            <Grid container className={classes.dataItem}>
              <Grid item xs={12}>
                メール: <a href={`mailto:${appointItem.mail_member}`}>{appointItem.mail_member}</a>
              </Grid>
            </Grid>

            <Grid container className={classes.dataItem}>
              <Grid item xs={12}>電話番号: {appointItem.phone_number_member}</Grid>
            </Grid>

            <Grid container className={classes.dataItem}>
              <Grid item xs={12}>開始時間: {appointItem.start_at}</Grid>
            </Grid>

            <Grid container className={classes.dataItem}>
              <Grid item xs={12}>終了時間: {appointItem.end_at}</Grid>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
