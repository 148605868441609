import PricesList from './PricesList';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PricesCreateEdit from './PricesCreateEdit';

const permission = localStorage.getItem('permission');

const Prices = {
    list: PricesList,
    create: permission === 'admin' ? PricesCreateEdit : undefined,
    edit: permission === 'admin' ? PricesCreateEdit : undefined,
    icon: MonetizationOnIcon,
};
export default Prices;
