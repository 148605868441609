import React, { Component, Fragment } from "react";
import MyContent from '../../layout/MyContent';
import createNotification from '../../common/Notifications';
import UserReservationsTable from "./UserReservationsTable";
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import rf from "../../requests/requests";
import * as _ from 'lodash';

const permission = localStorage.getItem('permission');

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_info: {
        id: props.id,
        full_name_kanji: '',
        full_name_kana: '',
        email : '',
        gender : '',
        birthday : '',
        phone_number : '',
      },
      reservations: [],
      pagination: {
        page: null,
        total: null,
      },
      page: 1,
      confirmationVisible: false,
      confirmationDelete: false,
      reservations_status: null,
      loading: false,
    };
    this.handleChangePagination = this.handleChangePagination.bind(this);
    this.onChangeReservationsStatus = this.onChangeReservationsStatus.bind(this);
    this.onUpdateMemo = this.onUpdateMemo.bind(this);
    this.deleteReservation = this.deleteReservation.bind(this);
    this.onDeleteReservation = this.onDeleteReservation.bind(this);
    this.closeChangeReservationsModal = this.closeChangeReservationsModal.bind(this);
    this.changeReservationsStatus = this.changeReservationsStatus.bind(this);
  }

  componentDidMount(){
    this.getUser();
    this.getListReservations({user_id: this.state.user_info.id});
  }

  async getUser(){
    await rf.get(`/admin/users/${this.state.user_info.id}`)
      .then(res => {
        this.setState({user_info: res.data})
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        message === 'Permission denied' && this.props.history.push('/users');
        createNotification(message);
      });
  }

  getListReservations = async (params) => {
    await rf.get(`/admin/reservations`, { params: params })
      .then(res => {
        if(!window._.isEmpty(res.data.reservations) || this.state.page === 1){
          this.setState(state => ({
            reservations: res.data.reservations,
            pagination: res.data.meta.pagination,
            page: res.data.meta.pagination.page,
          }));
        }else{
          this.getListReservations(window._.assign(params,{page: this.state.page - 1}));
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  };
  changeReservationsStatus(event, row) {
    this.setState(state => ({
      confirmationVisible: true,
      reservations_id: row.id,
      reservations_status: Number(event.target.value),
    }));
  }

  async onUpdateMemo(reservations_id, note) {
    const params = {memo: note}
    await rf.patch(`/admin/reservations/${reservations_id}/update_memo`, { reservation: params })
      .then(async res => {
        await this.getListReservations({user_id: this.state.user_info.id, page: this.state.page});
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0] && error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  async onChangeReservationsStatus() {
    const params = {
      reservations_status_id: this.state.reservations_status
    }
    this.setState({ loading: true});
    await rf.patch(`/admin/reservations/${this.state.reservations_id}`, { reservation: params })
      .then(async res => {
        await this.getListReservations({user_id: this.state.user_info.id, page: this.state.page});
        
      })
      .catch(error => {
        this.setState({ loading: false});
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(()=>{
        this.setState({ loading: false});
        this.closeChangeReservationsModal();
      })
  }

  deleteReservation(id) {
    this.setState(state => ({
      confirmationDelete: true,
      reservations_id: id,
    }));
  }

  async onDeleteReservation(){
    this.setState({ loading: true});
    await rf.delete(`/admin/reservations/${this.state.reservations_id}`)
      .then(async res => {
        await this.getListReservations({user_id: this.state.user_info.id, page: this.state.page});
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(()=>{
        this.setState({ loading: false});
        this.closeChangeReservationsModal();
      })
  }

  closeChangeReservationsModal() {
    this.setState(state => ({
      confirmationVisible: false,
      confirmationDelete: false,
    }));
  }
  
  paginationReservations = () => {
    const total = this.state.pagination.total;

    if(total >= 10) {
      return (
        <div style={{margin: '30px 0px', textAlign: 'center'}}>
          <Pagination className="pagination" count={Math.ceil(total / 10)} page={this.state.page} onChange={this.handleChangePagination} variant="outlined" shape="rounded" />
          <Typography style={{marginTop: '16px'}} className="page_of">{this.state.page} /  {(total / 10) < 1 ? '1' : Math.ceil(total / 10)}</Typography>
        </div>
      )
    }
  }

  handleChangePagination(event, value) {
    this.setState({ page: value});
    const params = {
      user_id: this.state.user_info.id,
      page: value
    }
    this.getListReservations(params)
  };

  render() {
    return (
      <MyContent onBack={true} name='users' header='ユーザー一覧'>
        <Fragment>
          <div className="user_detail">
              <div className="item"><span className="item_title">氏名（漢字）:</span> {this.state.user_info.full_name_kanji}</div>
              <div className="item"><span className="item_title">氏名（カナ）:</span> {this.state.user_info.full_name_kana}</div>
              {permission === 'admin' && <div className="item"><span className="item_title">メール:</span> {this.state.user_info.email}</div>}
              <div className="item"><span className="item_title">性別:</span> {this.state.user_info.gender}</div>
              <div className="item"><span className="item_title">生年月日:</span> {this.state.user_info.birthday}</div>
              {permission === 'admin' && <div className="item"><span className="item_title">電話番号:</span> {this.state.user_info.phone_number}</div>}
              <div className="item">
                <p className="item_title">予約一覧</p>
                {!_.isEmpty(this.state.reservations) &&
                  <UserReservationsTable
                    reservations={this.state.reservations}
                    changeReservationsStatus={this.changeReservationsStatus}
                    onUpdateMemo={this.onUpdateMemo}
                    deleteReservation={this.deleteReservation}
                  />
                }
                {this.paginationReservations()}
              </div>
          </div>
          <Dialog
            open={this.state.confirmationVisible || this.state.confirmationDelete}
            onClose={this.closeChangeReservationsModal}
            className="dialog_confirm"
          >
            <DialogTitle>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.state.confirmationVisible ? '予約状態を変更しても良いですか？' : '本当に削除しますか？取り消し出来ません。'}
              </DialogContentText>
            </DialogContent>
            {(this.state.confirmationVisible || this.state.confirmationDelete) &&
              <DialogActions className="group_botton">
                <Button color="primary" onClick={this.closeChangeReservationsModal} variant="outlined">
                  { this.state.confirmationVisible ? 'キャンセル' : 'いいえ'}
                </Button>
                {this.state.loading ?
                  <Button color="secondary" variant="outlined"><CircularProgress className="icon_loadding" color={'inherit'} size={20}/></Button>
                  :
                  <Button color="secondary" onClick={() => {this.state.confirmationVisible ? this.onChangeReservationsStatus() : this.onDeleteReservation()}} variant="outlined">
                    はい
                  </Button>
                }
              </DialogActions>}
          </Dialog>
        </Fragment>
      </MyContent>
    );
  }
}

export default UserDetail;
