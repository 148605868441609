import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import createNotification from '../../common/Notifications';
import rf from '../../requests/requests';
import {FastField, Field, Form, Formik} from 'formik';
import {Button} from '@material-ui/core';
import {BASE_API_URL} from '../../requests/requests';
import {patch, post} from 'axios';
import deLocale from "date-fns/locale/ja";
import {MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDateTimePicker} from 'formik-material-ui-pickers';
import {formatDateTime} from "../../utils/format";
import * as Yup from 'yup';

const CampaignSchema = Yup.object().shape({
  name: Yup.string()
    .min(0, '入力は必須です')
    .max(128, '128文字以内で入力してください')
    .required('入力は必須です'),
  percent: Yup.string()
    .min(0, '入力は必須です')
    .required('入力は必須です'),
  // start_at: Yup.string().required('入力は必須です'),
  // end_at: Yup.string().required('入力は必須です'),
  start_at: Yup.string().nullable().required('入力は必須です'),
  end_at: Yup.string().nullable().required('入力は必須です'),
});

class CampaignsCreateEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreate: !props.id,
      loading: false,
      initParams: {
        id: props.id,
        name: '',
        percent: '',
        start_at: new Date(),
        end_at: new Date(),
      }
    };
  }

  componentDidMount() {
    if(!this.state.isCreate){
      this.getCampaign();
    }
  }

  getCampaign(){
    rf.get(`/admin/campaigns/${this.state.initParams.id}`)
      .then(res => {
        res.data.percent = res.data.percent.toString();
        this.setState(state => ({
          initParams: res.data,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  validatePercent(value){
    if(!/^[0-9.]+$/i.test(value)) return '数字で入力してください';
    if(!/^(?:\d+)?(?:\.\d{0,1})?$/.test(value)) return '小数点第1位までで入力してください' ;
    if(parseFloat(value) <= 0 || parseFloat(value) >= 100) return '0.1%〜99.9%の範囲で入力してください';
    return '';
  }

  conFirmation = (values, actions) => {
    this.setState({loading: true})
    const params = {
      name: values.name,
      percent: values.percent,
      start_at: formatDateTime((new Date(values.start_at)).toString()),
      end_at: formatDateTime((new Date(values.end_at)).toString()),
    };
    if (!this.state.isCreate) {
      this.editCampaign(params, actions);
    } else {
      this.createCampaign(params, actions);
    }
  };

  createCampaign = (params, actions) => {
    const config = {
      headers: JSON.parse(localStorage.getItem('headers_request'))
    };
    const url = `${BASE_API_URL}/admin/campaigns`;
    const formData = new FormData();

    formData.append('campaign[name]', params.name);
    formData.append('campaign[percent]', params.percent);
    formData.append('campaign[start_at]', params.start_at);
    formData.append('campaign[end_at]', params.end_at);

    post(url, formData, config)
      .then(res => {
        this.props.history.push('/campaigns');
        actions.setSubmitting(false);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
        actions.setSubmitting(false);
      })
      .finally(() => this.setState({loading: false}))
  };

  editCampaign = (params, actions) => {
    

    const formData = new FormData();
    formData.append('campaign[name]', params.name);
    formData.append('campaign[percent]', parseFloat(params.percent));
    formData.append('campaign[start_at]', params.start_at);
    formData.append('campaign[end_at]', params.end_at);

    const url = `${BASE_API_URL}/admin/campaigns/${this.state.initParams.id}`;
    const config = {headers: JSON.parse(localStorage.getItem('headers_request'))};
    patch(url, formData, config)
      .then(res => {
        this.props.history.push('/campaigns');
        actions.setSubmitting(false);
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
        actions.setSubmitting(false);
      })
      .finally(() => this.setState({loading: false}))
  };

  render() {
    return (
      <MyContent onCreate={true} onBack={true} name='campaigns' header='キャンペーン' contentCreate='新規キャンペーン追加'>
        <Fragment>
          <Formik
            initialValues={this.state.initParams}
            onSubmit={this.conFirmation}
            validationSchema={CampaignSchema}
            enableReinitialize={true}
          >
            {({ values, errors, touched, isValidating, isValid }) => (
              <Form className="formik_campaigns_create">

                <div className="group_input_name clearfix">
                  <span className="title">タイトル {errors.name && touched.name && <span className='error'>{errors.name}</span>}</span>
                  <FastField className="input" type='text' name='name' placeholder="タイトル"/>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">割引率 {errors.percent && touched.percent && <span className='error'>{errors.percent}</span>}</span>
                  <FastField
                    className="input" name='percent' placeholder="割引率" validate={this.validatePercent}
                  />
                </div>

                <div className="group_input_name clearfix">
                  {/* <span className="title">開始日 {errors.start_at && touched.start_at && <span className='error'>{errors.start_at}</span>}</span> */}
                  <span className="title">開始日</span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                    <Field
                      component={KeyboardDateTimePicker}
                      name="start_at"
                      className="datetime-control"
                      inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="group_input_name clearfix">
                  {/* <span className="title">終了日 {errors.end_at && touched.end_at && <span className='error'>{errors.end_at}</span>}</span> */}
                  <span className="title">終了日</span>
                  {!this.props.isEdit &&
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                      <Field
                        component={KeyboardDateTimePicker}
                        name="end_at"
                        className="datetime-control"
                        minDate={values.start_at}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  }

                  {this.props.isEdit &&
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                      <Field
                        component={KeyboardDateTimePicker}
                        name="end_at"
                        className="datetime-control"
                        minDate={values.start_at}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  }
                </div>

                <Button variant="contained" className="button-save" disabled={this.state.loading} type="submit">保存する</Button>
              </Form>
            )}
          </Formik>
        </Fragment>
      </MyContent>
    );
  };
}

export default CampaignsCreateEdit;
