import React, { Component, Fragment } from "react";
import MyContent from "../../layout/MyContent";
import Avatar from '@material-ui/core/Avatar';
import createNotification from "../../common/Notifications";
import rf from "../../requests/requests";

// const permission = localStorage.getItem("permission");

class PractitionerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practitioner_info: {
        id: props.id,
        email: "",
        phone_number: "",
        full_name_kanji: "",
        full_name_kana: "",
        gender: "",
        selft_description: "",
        title_description: "",
        prime_description: "",
        link_to_image: "",
        belong_store: null,
        years_of_experience: "",
        story: "",
        favorites: [],
        slide_show: [],
        menus: [],
        qualifications: [],
        rank: null,
      },
    };
  }

  componentDidMount() {
    this.getPractitioner();
  }

  getPractitioner() {
    rf.get(`/admin/practitioners/${this.state.practitioner_info.id}`)
      .then((res) => {
        this.setState({ practitioner_info: res.data });
      })
      .catch((error) => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  render() {
    return (
        <MyContent onCreate={true} onBack={true} name='practitioners' header='施術者一覧' contentCreate='施術者を登録する'>
        <Fragment>
        <div className="practitioner_detail">
            <div className="item"><span className="item_title">プロフィール写真:</span><Avatar variant="square" className="img-show-file" height="40" width="40" src={this.state.practitioner_info.link_to_image} alt="" /></div>
            <div className="item"><span className="item_title">画像:</span>
                <div>
                    {this.state.practitioner_info.slide_show.map((src, index)=>(
                        <img key={index} className="img-show-file" height="40" width="40" src={src.url} alt="" />))
                    }
                </div>
            </div>
            <div className="item"><span className="item_title">メール:</span> {this.state.practitioner_info.email}</div>
            <div className="item"><span className="item_title">電話番号:</span> {this.state.practitioner_info.phone_number}</div>
            <div className="item"><span className="item_title">氏名（漢字）:</span> {this.state.practitioner_info.full_name_kanji}</div>
            <div className="item"><span className="item_title">氏名（カナ）:</span> {this.state.practitioner_info.full_name_kana}</div>
            <div className="item"><span className="item_title">経験年数:</span> {this.state.practitioner_info.years_of_experience}年</div>
            <div className="item"><span className="item_title">経歴:</span> {this.state.practitioner_info.story}</div>
            <div className="item"><span className="item_title">趣味:</span> {this.state.practitioner_info.favorites.map(favorite => favorite.name).toString()}</div>
            <div className="item"><span className="item_title">所属欄:</span> {this.state.practitioner_info.belong_store?.name}</div>
            <div className="item"><span className="item_title">性別:</span> {this.state.practitioner_info.gender === 'female' ? '女性' : '男性'}</div>
            <div className="item" style={{display: 'flex'}}><div className="item_title">施術師からの一言:</div><div style={{whiteSpace: "pre-line"}}>{this.state.practitioner_info.selft_description}</div></div>
            <div className="item"><div className="item_title">Primeからの一言:</div>
                <div>
                    <div><span className="item_title">タイトル:</span> {this.state.practitioner_info.title_description}</div>
                    <div style={{display: 'flex'}}><div className="item_title">本文:</div><div style={{whiteSpace: "pre-line"}}>{this.state.practitioner_info.prime_description}</div></div>
                </div>
            </div>
            <div className="item"><span className="item_title">お悩み:</span> {this.state.practitioner_info.menus.map(menu => menu.name).toString()}</div>
            <div className="item"><span className="item_title">保有資格:</span> {this.state.practitioner_info.qualifications.map(qualification => qualification.name).toString()}</div>
            <div className="item"><span className="item_title">担当施術師:</span> {this.state.practitioner_info.rank?.name}</div>
          </div>
        </Fragment>
      </MyContent>
    );
  }
}

export default PractitionerDetail;
