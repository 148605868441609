import React from 'react';
import { Link } from 'react-router-dom';
import {Button, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from "@material-ui/core/styles";

const permission = localStorage.getItem('permission');

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rowItem: {
    padding: 5,
  },
  rowItemTitle: {
    fontWeight: '600',
    paddingRight: 7,
  },
  iconRowItem: {
    padding: 10,
  },
  ratingColumn: {
    position: 'relative',
  },
  [theme.breakpoints.down("md")]: {
    ratingColumn: {
      position: 'relative',
      display: 'inline-block',
    },
  },
  ratingNumber: {
  },
  ratingControl: {
    float: 'left',
    marginRight: 5,
  },
}));

const RatingCell = (props) => {
  const classes = useStyles();
  const {value} = props;
  return (
    <div className={classes.ratingColumn}>
      <span>{value}</span>
      <Rating className={classes.ratingControl} name="read-only" value={value} readOnly />
    </div>
  );
};

const TableRowLarge = (props) => {
  const {row} = props;
  return (
    <TableRow>
      <TableCell align="left">{row.title}</TableCell>
      <TableCell align="left" style={{whiteSpace: 'pre-line'}}>{row.body}</TableCell>
      <TableCell align="left"><RatingCell value={row.communication} /></TableCell>
      <TableCell align="left"><RatingCell value={row.symptom_improvement} /></TableCell>
      <TableCell align="left"><RatingCell value={row.customer_satisfaction} /></TableCell>
      <TableCell align="left">{row.display_name}</TableCell>
      <TableCell align="left">{row.status || ''}</TableCell>
      {permission === 'admin' && <TableCell align="left"><Button variant="contained" component={Link} to={`/reviews/${row.id}`}><EditIcon /></Button></TableCell>}
    </TableRow>
  );
};

const TableRowSmall = (props) => {
  const classes = useStyles();
  const {row} = props;
  const theme = useTheme();
  const isSmallMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <TableRow>
      <TableCell align="left">

        <div className={classes.rowItem}><span className={classes.rowItemTitle}>タイトル:</span> {row.title}</div>
        <div className={classes.rowItem} style={{whiteSpace: 'pre-line'}}><span className={classes.rowItemTitle}>本文:</span> {row.body}</div>
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>接客:</span>
          <RatingCell value={row.communication} />
        </div>
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>満足度:</span>
          <RatingCell value={row.customer_satisfaction} />
        </div>
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>技術:</span>
          <RatingCell value={row.symptom_improvement} />
        </div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>表示名:</span> {row.display_name}</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>状態:</span> {row.status || ''}</div>

        {isSmallMobileScreen && permission === 'admin' && 
          <div className={classes.rowItem}>
            <Button variant="contained" component={Link} to={`/reviews/${row.id}`}><EditIcon /></Button>
          </div>
        }
      </TableCell>

      {!isSmallMobileScreen && permission === 'admin' &&
        <TableCell align="left">
          <div className={classes.iconRowItem}>
            <Button variant="contained" component={Link} to={`/reviews/${row.id}`}><EditIcon /></Button>
          </div>
        </TableCell>
      }
    </TableRow>
  );
};

function ReviewsTable(props) {
  const {reviews} = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Table aria-label="simple table">
      <TableHead>
        {!isMobileScreen &&
          <TableRow>
            <TableCell align="left">タイトル</TableCell>
            <TableCell align="left">本文</TableCell>
            <TableCell align="left">接客</TableCell>
            <TableCell align="left">技術</TableCell>
            <TableCell align="left">満足度</TableCell>
            <TableCell align="left">表示名</TableCell>
            <TableCell align="left">状態</TableCell>
            {permission === 'admin' && <TableCell align="left">編集する</TableCell>}
          </TableRow>
        }
      </TableHead>
      <TableBody>
        {reviews.map((row, index) => {
          if (isMobileScreen) {
            return <TableRowSmall
              row={row}
              key={index}
            />
          }
          return (
            <TableRowLarge
              row={row}
              key={index}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default ReviewsTable;
