import axios from "axios";
import createNotification from '../common/Notifications';

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const API = axios.create({
  baseURL: BASE_API_URL,
});

export const setHeader = () => {
  const headers_params = JSON.parse(localStorage.getItem("headers_request"));
  API.defaults.headers.common = headers_params;
};

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      localStorage.setItem("not_authenticated", true);
      createNotification("ログイン時間制限")
    }
    return Promise.reject(error);
  }
);
export default API;
