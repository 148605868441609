import PersonIcon from '@material-ui/icons/Person';
import UsersList from './UsersList';
import UserDetail from './UserDetail';

const Users = {
    list: UsersList,
    show: UserDetail,
    icon: PersonIcon,
}
export default Users;
