import PromotionsList from './PromotionsList';
import CodeIcon from '@material-ui/icons/Code';
import PromotionsCreateEdit from './PromotionsCreateEdit';

const permission = localStorage.getItem('permission');
const Promotions = {
    list: PromotionsList,
    edit: permission === 'admin' ? PromotionsCreateEdit : undefined,
    icon: CodeIcon,
};
export default Promotions;
