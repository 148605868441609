import React, { Component, Fragment } from 'react';
import MyContent from '../../layout/MyContent';
import createNotification from '../../common/Notifications';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import rf, {BASE_API_URL} from '../../requests/requests';
import { post } from 'axios';
import {validEmailRegex} from "../../utils";
import ChipInput from 'material-ui-chip-input';
import Chip from "@material-ui/core/Chip";
class PractitionersCreate extends Component {
   constructor(props) {
    super(props);
    this.state = {
      practitioner_info: {},
      menus: [],
      qualifications: [],
      prices_60: [],
      prices_90: [],
      payments_statuses: [],
      ranks: [],
      files: null,
      image: null,
      error_img: false,
      belong_stores: [],
      slide_files: [],
      slide_images: [],
      chips: [],
      loading: false,
    };

    this.getPractitioner = this.getPractitioner.bind(this);
    this.getMenus = this.getMenus.bind(this);
    this.getQualifications = this.getQualifications.bind(this);
    this.getRanks = this.getRanks.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.getPaymentStatus = this.getPaymentStatus.bind(this);
    this.conFirmation = this.conFirmation.bind(this);
    this.createPractitioners = this.createPractitioners.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.validateLinkToImage = this.validateLinkToImage.bind(this);
    this.handleUploadSlideImage =  this.handleUploadSlideImage.bind(this);
    this.validateSlides =  this.validateSlides.bind(this);
    this.handleDeleteChip =  this.handleDeleteChip.bind(this);
    this.handleAddChip = this.handleAddChip.bind(this);
    this.validateHobby =  this.validateHobby.bind(this);
  }

  componentDidMount() {
    this.getListBelongStores();
    this.getMenus();
    this.getQualifications();
    this.getRanks();
    this.getPrice();
    this.getPaymentStatus();
  }

  getListBelongStores = () => {
    rf.get(`/admin/belong_stores`)
      .then(res => {
        this.setState(state => ({
          belong_stores: res.data,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  };

  getPractitioner(params) {
    rf.get(`/admin/practitioners/${this.props.practitionerID}`, { params: params })
      .then(res => {
        this.setState(state => ({
          practitioner_info: res.data,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  }

  getMenus() {
    rf.get(`/menus`)
      .then(res => {
        this.setState(state => ({
          menus: res.data,
        }));
      })
      .catch(error => {
        console.log(error)
      });
  }

  getQualifications() {
    rf.get(`/qualifications`)
      .then(res => {
        this.setState(state => ({
          qualifications: res.data,
        }));
      })
      .catch(error => {
        console.log(error)
      });
  }

  getRanks() {
    rf.get(`/ranks`)
      .then(res => {
        this.setState(state => ({
          ranks: res.data,
        }));
      })
      .catch(error => {
        console.log(error)
      });
  }

  getPrice(){
    this.getPriceByMinute({minute: 60});
    this.getPriceByMinute({minute: 90});
  }

  getPriceByMinute(params){
    rf.get(`/admin/prices`,{params})
      .then(res => {
        const pagination = res.data.meta.pagination;
        params.minute === 60 ? this.setState(state => ({prices_60: [...state.prices_60].concat(res.data.prices)})) : this.setState(state => ({prices_90: [...state.prices_90].concat(res.data.prices)}));
        if(pagination.page*10 < pagination.total){
          this.getPriceByMinute({minute: params.minute, page: pagination.page + 1})
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  getPaymentStatus(){
    rf.get(`admin/payments_statuses`)
      .then(res => {
        this.setState(state => ({
          payments_statuses: res.data,
        }));
      })
      .catch(error => {
        console.log(error)
      });
  }

  validateEmail(value) {
    let error;
    error = validEmailRegex.test(value)
          ? (value.length > 225 ? '255文字以内で入力してください' : '')
          : 'メールアドレス形式で入力してください';
    return error;
  }

  validatePhoneNumber(value) {
    let error;
    if (!/^[0-9]+$/i.test(value) || value.length !== 11) {
      error = '11ケタの数値で入力してください';
    }
    return error;
  }

  validateFullNameKanji(value) {
    let error;
    if (!/^([\u3041-\u3096]|[ ]|[\u30A1-\u30FA]|[一ー-龥]|[a-zA-Z])+$/i.test(value) || value.length > 128) {
      error = '「漢字・カタカナ・ひらがな」のみ入力可能です';
    }
    return error;
  }

  validateFullNameKana(value) {
    let error;
    if (!/^([\u30A1-\u30FA]|[ ]|[一ー]|[a-zA-Z])+$/i.test(value) || value.length > 128) {
      error = 'カタカナで入力してください';
    }
    return error;
  }

  validateSelfDescription(value){
    let error;
    error = value.length > 1000 ? '本文は、最大1000文字です。' : '';
    return error;
  }

  validateTitlePrime(value){
    let error;
    error = value.length > 20 ? 'タイトルは、最大20文字です。' : '';
    return error;
  }

  validateContent(value){
    let error;
    error = value.length > 2000 ? '本文は、最大2,000文字です。' : '';
    return error;
  }

  validateSlides(){
    const allowedExtensions = /(\.jpg|\.png)$/i;  
    if(this.state.slide_files.length > 10) return '画像は 10 枚まで登録可能です';
    for (const file of this.state.slide_files) {
      if(!allowedExtensions.exec(file.name)) return file.name + ': 画像ファイルではないようです、画像ファイルを選択してください';
      if(file.size / 1024 > 1024 * 5) return file.name + ': アップロード可能な画像サイズは 5MB までとなっています';
    }
    return '';
  }

  validateCareer(value){
    let error;
    error = value.length > 200 ? '200文字以内で入力してください' : '';
    return error;
  }

  validateHobby(){
    if(this.state.chips.length > 3) return '趣味は 3 つまで入力できます';
    for (const chip of this.state.chips) {
      if(chip.length > 16) return chip + ': 16文字以内で入力してください';
    }
    return '';
  }

  validateBelongStoreId(value) {
  }

  validateLinkToImage() {
    const allowedExtensions = /(\.jpg|\.png)$/i;
    if(this.state.files){
      if(!allowedExtensions.exec(this.state.files.name)) return this.state.files.name + ': 画像ファイルではないようです、画像ファイルを選択してください';
      if(this.state.files.size / 1024 > 1024 * 5) return this.state.files.name + ': アップロード可能な画像サイズは 5MB までとなっています';
    }
    return '';
  }

  validateMenus(value) {
    let error;
    if (value.length < 1) {
      error = 'メニューを少なくとも1つ選択してください';
    }
    return error;
  }

  validateQualification(value) {
    let error;
    if (value.length < 1) {
      error = '少なくとも1つの資格を選択してください';
    }
    return error;
  }

  validatePrice(value){
    let error;
    if (value.length < 1) {
      error = '1つの価格を選択してください';
    }
    return error;
  }

  validatePaymentStatus(value) {
    let error;
    if (value.length < 1) {
      error = '少なくとも1つの支払い手段を選択してください';
    }
    return error;
  }

  validateRankId(value) {
    let error;
    if (!value) {
      error = '担当施術師で選ぶ';
    }
    return error;
  }

  conFirmation(values) {
    this.setState({loading: true})
    const params = {
      email: values.email,
      phone_number: values.phone_number,
      full_name_kanji: values.full_name_kanji,
      full_name_kana: values.full_name_kana,
      gender: values.gender,
      belong_store_id: values.belong_store_id,
      menu_ids: values.menu_ids,
      qualification_ids: values.qualification_ids,
      payments_status_ids: values.payments_status_ids,
      rank_id: values.rank_id,
      self_description: values.self_description,
      prime_title: values.prime_title,
      prime_content: values.prime_content,
      story: values.career,
      years_of_experience: values.years_of_experience,
      price_60: values.price_60,
      price_90: values.price_90,
    }
    this.createPractitioners(params)
  }

  createPractitioners(params) {
    const config = {
      headers: JSON.parse(localStorage.getItem('headers_request'))
    }
    const url = `${BASE_API_URL}/admin/practitioners`;
    const formData = new FormData();
    formData.append('practitioner[link_to_image]', this.state.files)
    formData.append('practitioner[email]', params.email)
    formData.append('practitioner[phone_number]', params.phone_number)
    formData.append('practitioner[full_name_kanji]', params.full_name_kanji)
    formData.append('practitioner[full_name_kana]', params.full_name_kana)
    formData.append('practitioner[belong_store_id]', params.belong_store_id)
    formData.append('practitioner[gender]', params.gender)
    formData.append('practitioner[selft_description]', params.self_description)
    formData.append('practitioner[title_description]', params.prime_title)
    formData.append('practitioner[prime_description]', params.prime_content)
    formData.append('practitioner[rank_id]', params.rank_id)
    formData.append('practitioner[story]', params.story)
    formData.append('practitioner[years_of_experience]', params.years_of_experience ? params.years_of_experience : 0)
    formData.append('practitioner[all_favorites]', this.state.chips.toString())
    formData.append('practitioner[price_60]', params.price_60)
    formData.append('practitioner[price_90]', params.price_90)

    this.state.slide_files.forEach(file => {
      formData.append('practitioner[practitioner_photos][]', file)
    })
    params.menu_ids.forEach(element => {
      formData.append('practitioner[menu_ids][]', element)
    });
    params.qualification_ids.forEach(element => {
      formData.append('practitioner[qualification_ids][]', element)
    });
    params.payments_status_ids.forEach(element => {
      formData.append('practitioner[payments_status_ids][]', element)
    });

    post(url, formData, config)
      .then(res => {
        this.props.history.push('/practitioners');
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(() => this.setState({loading: false}))
  }

  handleChangeImage(evt) {
    var self = this;
    var reader = new FileReader();
    var file = evt.target.files[0];
    // var image = evt.target.value;
    reader.onload = function(upload) {
    self.setState({
        files: file,
        image: upload.target.result
    });
    };
    reader.readAsDataURL(file);
  }

  handleUploadSlideImage(evt) {
    const self = this;
    const files = evt.target.files;
    for(let i = 0 ; i < files.length ;i++){
      const file = files[i];
      const reader = new FileReader();
      reader.onload = function(upload) {
        self.setState(state=>({
          slide_files: [...state.slide_files, file],
          slide_images: [...state.slide_images, upload.target.result]
        }));
        };
      reader.readAsDataURL(file);
    }
  }

  deleteSlideImage(index){
    let slide_files = [...this.state.slide_files];
    let slide_images = [...this.state.slide_images];
    slide_files.splice(index, 1);
    slide_images.splice(index, 1);
    this.setState({
      slide_files: slide_files,
      slide_images: slide_images
    })
  }

  handleAddChip(chip){
    this.setState(state=>({chips: [...state.chips,chip]}))
  }

  handleDeleteChip(chip, index){
    const chips = [...this.state.chips];
    chips.splice(index,1);
    this.setState(state=>({chips: chips}))
  }
  
  render() {
    const chipRenderer=({ chip, className, handleClick, handleDelete }, key)=>{
      return <Chip
        className={className}
        key={key}
        label={chip}
        onDelete={handleDelete}
      />
    }
    return (
      <MyContent onCreate={true} onBack={true} name='practitioners' header='施術者一覧' contentCreate='施術者を登録する'>
        <Fragment>
          <Formik
            initialValues={{
              img: '',
              email: '',
              phone_number: '',
              full_name_kanji: '',
              full_name_kana: '',
              gender: 'female',
              self_description: '',
              prime_title: '',
              prime_content: '',
              menu_ids: [],
              qualification_ids: [],
              payments_status_ids: [],
              rank_id: '',
              belong_store_id: '',
              years_of_experience: '',
              career: '',
              price_60: '',
              price_90: '',
            }}
            onSubmit={this.conFirmation}
          >
            {({ errors, touched, isValidating, isValid }) => (
              <Form className="formik_practitioners_create">
                <div className="group_input_name clearfix">
                  <span className="title">プロフィール写真 {(!this.state.img || (errors.img && touched.img)) && <span className='error'>{errors.img}</span>}</span>
                  <input type="file" accept="image/png, image/jpeg" name="file"
                                  className="upload-file"
                                  onChange={this.handleChangeImage}
                                  encType="multipart/form-data"
                                  required/>
                  <Field type="file" name="img" accept="image/png, image/jpeg"  style={{ display: 'none' }} validate={this.validateLinkToImage}/>
                  { this.state.image &&
                    <Avatar variant="square" className="img-show-file" height="40" width="40" src={this.state.image} alt="" />
                  }
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">画像 {errors.slide_show && <span className='error'>{errors.slide_show}</span>}</span>
                  <div style={{display: 'flex'}}>
                  { this.state.slide_images.map((src, index)=>(
                  <div key={index} onClick={()=>this.deleteSlideImage(index)} className="primary">
                    <img className="img-show-file" height="40" width="40" src={src} alt="" />
                    <div className="secondary">
                    <p>Delete</p>
                    </div>
                  </div>
                  ))}
                  </div>
                  <input type="file" accept="image/png, image/jpeg" name='slide'
                                  className="upload-file"
                                  multiple
                                  onChange={this.handleUploadSlideImage}
                                  encType="multipart/form-data"/>
                  <Field type="file" name="slide_show" accept="image/png, image/jpeg"  style={{ display: 'none' }} validate={this.validateSlides}/>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">メール {errors.email && touched.email && <span className='error'>{errors.email}</span>}</span>
                  <Field className="input" type='text' name='email' placeholder="Email" validate={this.validateEmail}/>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">電話番号 {errors.phone_number && touched.phone_number && <span className='error'>{errors.phone_number}</span>}</span>
                  <Field className="input" type='text' name='phone_number' placeholder="例）09012345678" validate={this.validatePhoneNumber}/>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">氏名（漢字）{errors.full_name_kanji && touched.full_name_kanji && <span className='error'>{errors.full_name_kanji}</span>}</span>
                  <Field className="input" type='text' name='full_name_kanji' placeholder="太郎" validate={this.validateFullNameKanji}/>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">氏名（カナ）{errors.full_name_kana && touched.full_name_kana && <span className='error'>{errors.full_name_kana}</span>}</span>
                  <Field className="input" type='text' name='full_name_kana' placeholder="タナカ" validate={this.validateFullNameKana}/>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">経験年数 {errors.years_of_experience && touched.years_of_experience && <span className='error'>{errors.years_of_experience}</span>}</span>
                  <Field as="select" name="years_of_experience">
                    <option value=""></option>
                    {
                      Array(99).fill().map((value, index)=>(index + 1)).map(value => (
                        <option key={value} value={value}>{value+'年'}</option>
                      ))
                    }
                  </Field>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">経歴 {errors.career && touched.career && <span className='error'>{errors.career}</span>}</span>
                  <Field className="input" type='text' name='career' placeholder="経歴" validate={this.validateCareer}/>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">趣味 {errors.hobby && <span className='error'>{errors.hobby}</span>}</span>
                  <Field name="hobby" style={{ display: 'none' }} validate={this.validateHobby}/>
                  <ChipInput className="input" variant="outlined" value={this.state.chips} chipRenderer={chipRenderer} onAdd={(chip) => this.handleAddChip(chip)} onDelete={(chip, index) => this.handleDeleteChip(chip, index)}/>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">所属欄</span>
                  <Field as="select" name="belong_store_id">
                    <option value=""></option>
                    {
                      this.state.belong_stores && this.state.belong_stores.map(belong_store => (
                        <option key={belong_store.id} value={belong_store.id}>{belong_store.name}</option>
                      ))
                    }
                  </Field>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">性別</span>
                  <Field as="select" name="gender">
                      <option value="female">女性</option>
                      <option value="male">男性</option>
                  </Field>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">施術師からの一言 {errors.self_description && touched.self_description && <span className='error'>{errors.self_description}</span>}</span>
                  <Field className="input" style={{width: 500, maxWidth: '100%', fontSize: 14, lineHeight: '20px', padding: '10px 15px'}} rows="3" name='self_description' component="textarea" placeholder="施術師からの一言" validate={this.validateSelfDescription}/>
                </div>
                <div className="group_input_name clearfix">
                <span>Primeからの一言</span>
                <div>
                  <span>タイトル {errors.prime_title && touched.prime_title && <span className='error'>{errors.prime_title}</span>}</span>
                  <Field className="input" type='text' name='prime_title' placeholder="タイトル" validate={this.validateTitlePrime}/>
                </div>
                <br/>
                <div>
                  <span>本文 {errors.prime_content && touched.prime_content && <span className='error'>{errors.prime_content}</span>}</span>
                  <Field name='prime_content' className="input" component="textarea" validate={this.validateContent} style={{width: 500, maxWidth: '100%', fontSize: 14, lineHeight: '20px', padding: '10px 15px'}} rows="3" type='text' placeholder="本文" />
                </div>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">対応できる「お悩み」 {errors.menu_ids && touched.menu_ids && <span className='error'>{errors.menu_ids}</span>}</span>
                  {
                    this.state.menus && this.state.menus.map((menu, index) => (
                      <label className="item_menu" key={index} htmlFor={menu.name}>
                        <Field type="checkbox" name="menu_ids" value={menu.id.toString()} id={menu.name} validate={this.validateMenus}/>
                        <span className="name">{menu.name}</span>
                      </label>
                    ))
                  }
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">保有資格 {errors.qualification_ids && touched.qualification_ids && <span className='error'>{errors.qualification_ids}</span>}</span>
                  {
                    this.state.qualifications && this.state.qualifications.map((qualification, index) => (
                      <label className="item_menu" key={index} htmlFor={qualification.name}>
                        <Field type="checkbox" name="qualification_ids" value={qualification.id.toString()} id={qualification.name} validate={this.validateQualification}/>
                        <span className="name">{qualification.name}</span>
                      </label>
                    ))
                  }
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">価格 60分{errors.price_60 && touched.price_60 && <span className='error'>{errors.price_60}</span>}</span>
                  <Field as="select" name="price_60" validate={this.validatePrice}>
                    <option value=""></option>
                      {this.state.prices_60.map((price, index) =>  <option key={index} value={price.id}>{parseInt(price.price)}円 (税込)（{price.minute}分）</option>)}
                  </Field>
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">価格 90分{errors.price_90 && touched.price_90 && <span className='error'>{errors.price_90}</span>}</span>
                  <Field as="select" name="price_90" validate={this.validatePrice}>
                    <option value=""></option>
                      {this.state.prices_90.map((price, index) =>  <option key={index} value={price.id}>{parseInt(price.price)}円 (税込)（{price.minute}分）</option>)}
                  </Field>
                </div>
                <div className="group_input_name">
                  <span className="title">支払い手段 {errors.payments_status_ids && touched.payments_status_ids && <span className='error'>{errors.payments_status_ids}</span>}</span>
                  {
                    this.state.payments_statuses && this.state.payments_statuses.map((payments_status, index) => (
                      <label className="item_menu" key={index} htmlFor={payments_status.status}>
                        <Field type="checkbox" key={index} name="payments_status_ids" value={payments_status.id.toString()} id={payments_status.status} validate={this.validatePaymentStatus}/>
                        <span className="name">{payments_status.status}</span>
                      </label>
                    ))
                  }
                </div>
                <div className="group_input_name clearfix">
                  <span className="title">担当施術師 {errors.rank_id && touched.rank_id && <span className='error'>{errors.rank_id}</span>}</span>
                  <Field as="select" name="rank_id" validate={this.validateRankId}>
                      <option></option>
                      {
                        this.state.ranks && this.state.ranks.map((rank, index) => (
                          <option value={rank.id} key={index}>{rank.name}</option>
                        ))
                      }
                  </Field>
                </div>
                <Button variant="contained" className="button-save" disabled={this.state.loading} type="submit">保存する</Button>
              </Form>
            )}
          </Formik>
        </Fragment>
      </MyContent>
      
    );
  };
}

export default PractitionersCreate;
