import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import rf from '../../requests/requests';
import createNotification from '../../common/Notifications';

import CampaignsTable from "./CampaignsTable";
import './style.scss';

class CampaignsList extends Component {
   constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        page: null,
        total: null,
      },
      page: 1,
      recordId: null,
      dataParams: {},
    };
  }

  componentDidMount() {
    this.getDataList();
  }

  getDataList = (params) => {
    rf.get(`/admin/campaigns`, { params: params })
      .then(res => {
        this.setState(state => ({
          data: res.data.campaigns,
          pagination: res.data.meta.pagination,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  };

  pagination = () => {
    const total = this.state.pagination.total;
    if(total >= 10) {
      return (
        <div style={{margin: '30px 0px', textAlign: 'center'}}>
          <Pagination className="pagination" count={Math.ceil(total / 10)} page={this.state.page} onChange={this.handleChangePagination} variant="outlined" shape="rounded" />
          <Typography style={{marginTop: '16px'}} className="page_of">{this.state.page} /  {(total / 10) < 1 ? '1' : Math.ceil(total / 10)}</Typography>
        </div>
      )
    }
  };

  handleChangePagination = (event, value) => {
    this.setState({ page: value});
    const params = {
      page: value
    };
    this.getDataList(params)
  };

  render() {
    return (
      <MyContent onCreate={true} name='campaigns' header='キャンペーン' contentCreate='新規キャンペーン追加'>
        <Fragment>
          <CampaignsTable
            data={this.state.data}
          />
          {this.pagination()}
        </Fragment>
      </MyContent>
    );
  };
}

export default CampaignsList;
