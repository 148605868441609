import React from 'react';
import { Link } from 'react-router-dom';
import {Button, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from "@material-ui/core/styles";

const permission = localStorage.getItem('permission');

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rowItem: {
    padding: 5,
  },
  rowItemTitle: {
    fontWeight: '600',
    paddingRight: 7,
  },
  iconRowItem: {
    padding: 10,
  },
  ratingColumn: {
    position: 'relative',
  },
  [theme.breakpoints.down("md")]: {
    ratingColumn: {
      position: 'relative',
      display: 'inline-block',
    },
  },
  ratingNumber: {
  },
  ratingControl: {
    float: 'left',
    marginRight: 5,
  },
}));

const formatDate = (date) => {
  const d = new Date(date);
  return d.toLocaleDateString("ja", {year: "numeric", month: "2-digit", day: "2-digit"});
}

const TableRowLarge = (props) => {
  const {row,type} = props;
  return (
    <TableRow>
      {type === "admin_promotions" && <TableCell align="left">{row.title}</TableCell>}
      {type === "invitation_coupons" && <TableCell align="left">{row.owner_name.full_name_kanji || row.owner_name.name}</TableCell>}
      {type === "invitation_coupons" && <TableCell align="left">{row.users_invited.map(user => (<div>- {user.full_name_kanji || user.name}</div>))}</TableCell>}
      {type === "contributed_coupons" && <TableCell align="left">{row.owner_name.full_name_kanji || row.owner_name.name}</TableCell>}
      <TableCell align="left">{row.discount_code}</TableCell>
      <TableCell align="left">{row.discount_rate} %</TableCell>
      <TableCell align="left">{row.usable_number_of_times} 回</TableCell>
      {type === "admin_promotions" && <TableCell align="left">{row.scope ? (row.scope === 'old_user' ? '既存ユーザーのみ' : '新規ユーザーのみ') : ''}</TableCell>}
      <TableCell align="left">{formatDate(row.expired_at)}</TableCell>
      {permission === 'admin' && <TableCell align="left"><Button variant="contained" component={Link} to={`/${type}/${row.id}`}><EditIcon /></Button></TableCell>}
    </TableRow>
  );
};

const TableRowSmall = (props) => {
  const classes = useStyles();
  const {row,type} = props;
  const theme = useTheme();
  const isSmallMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <TableRow>
      <TableCell align="left">
        {type === "admin_promotions" && <div className={classes.rowItem}><span className={classes.rowItemTitle}>タイトル:</span> {row.title}</div>}
        {type === "invitation_coupons" && <div className={classes.rowItem}><span className={classes.rowItemTitle}>紹介者:</span> {row.owner_name.full_name_kanji || row.owner_name.name}</div>}
        {type === "invitation_coupons" && <div className={classes.rowItem}><span className={classes.rowItemTitle}>新規利用ユーザー:</span> {row.users_invited.map(user => (<div>- {user.full_name_kanji || user.name}</div>))}</div>}
        {type === "contributed_coupons" && <div className={classes.rowItem}><span className={classes.rowItemTitle}>利用者:</span> {row.owner_name.full_name_kanji || row.owner_name.name}</div>}
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>クーポンコード:</span> {row.discount_code}</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>割引率:</span> {row.discount_rate} %</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>残回数:</span> {row.usable_number_of_times} 回</div>
        {type === "admin_promotions" && <div className={classes.rowItem}><span className={classes.rowItemTitle}>Scope:</span> {row.scope ? (row.scope === 'old_user' ? '既存ユーザーのみ' : '新規ユーザーのみ') : ''}</div>}
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>使用期日:</span> {formatDate(row.expired_at)}</div>

        {isSmallMobileScreen && permission === 'admin' &&
          <div className={classes.rowItem}>
            <Button variant="contained" component={Link} to={`/${type}/${row.id}`}><EditIcon /></Button>
          </div>
        }
      </TableCell>

      {!isSmallMobileScreen && permission === 'admin' &&
        <TableCell align="left">
          <div className={classes.iconRowItem}>
            <Button variant="contained" component={Link} to={`/${type}/${row.id}`}><EditIcon /></Button>
          </div>
        </TableCell>
      }
    </TableRow>
  );
};

function PromotionsTable(props) {
  const {data,type,onShowEditRecord} = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Table aria-label="simple table">
      <TableHead>
        {!isMobileScreen &&
          <TableRow>
            {type === "admin_promotions" && <TableCell align="left">タイトル</TableCell>}
            {type === "invitation_coupons" && <TableCell align="left">紹介者</TableCell>}
            {type === "invitation_coupons" && <TableCell align="left">新規利用ユーザー</TableCell>}
            {type === "contributed_coupons" && <TableCell align="left">利用者</TableCell>}
            <TableCell align="left">クーポンコード</TableCell>
            <TableCell align="left">割引率</TableCell>
            <TableCell align="left">残回数</TableCell>
            {type === "admin_promotions" && <TableCell align="left">Scope</TableCell>}
            <TableCell align="left">使用期日</TableCell>
            {permission === 'admin' && <TableCell align="left">編集する</TableCell>}
          </TableRow>
        }
      </TableHead>
      <TableBody>
        {data.map((row, index) => {
          if (isMobileScreen) {
            return <TableRowSmall row={row} type={type} key={index} onShowEditRecord={onShowEditRecord} />
          }
          return (
            <TableRowLarge row={row} type={type} key={index} onShowEditRecord={onShowEditRecord} />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default PromotionsTable;
