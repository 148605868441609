import * as React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
  },
  menuWrapper: {
  }
}));

const MyMenu = (props) => {
  const {onMenuClick} = props;
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const classes = useStyles();

  return (
    <div className={classes.menuWrapper}>
      {resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
    </div>
  );
};

export default MyMenu;
