import * as React from 'react';
import {Sidebar} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

// Custom Menu SideBar:
// https://marmelab.com/react-admin/Theming.html#sidebar-customization
// node_modules/ra-ui-materialui/esm/layout/Sidebar.d.ts:13
const useSidebarStyles = makeStyles(theme => ({
  drawerPaper: {
    maxWidth: 240,
  },
  [theme.breakpoints.down("md")]: {
    drawerPaper: {
      maxWidth: 200,
    },
  },
  [theme.breakpoints.down("sm")]: {
    drawerPaper: {
      maxWidth: 160,
    },
  },
}));

const MySidebar = props => {
  const classes = useSidebarStyles();
  return (
    <Sidebar classes={classes} {...props} />
  );
};

export default MySidebar;
