import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import TextField from '@material-ui/core/TextField';
import createNotification from '../../common/Notifications';
import {FastField, Field, Form, Formik} from 'formik';
import {Button} from '@material-ui/core';
import rf, {BASE_API_URL} from '../../requests/requests';
import {patch, post} from 'axios';
import * as Yup from 'yup';
import {validateEmail, validateFullNameKanji} from "../../utils/validate";

const permission = localStorage.getItem('permission');
const practitioner_id = localStorage.getItem('practitioner_id');

const ReservationSchema = Yup.object().shape({
  reserved_at: Yup.string().required('入力は必須です'),
  // practitioner_id: Yup.string().required('入力は必須です'),
  reserved_time: Yup.string().required('Please select reserved time'),
  minute: Yup.string().required('入力は必須です'),
});

class ReservationsCreateEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initParams: this.buildInitFormParams(),
      menus: [],
      practitioners: [],
      pagination: {},
      timeCanReserved: [],
      reservedTime: null,
      errorPhoneNumber: false,
      loading: false,
    };
    this.onChangeSelectDate =  this.onChangeSelectDate.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
    this.getUserByPhoneNumber = this.getUserByPhoneNumber.bind(this);
  }

  componentDidMount() {
    this.setState({
      initParams: this.buildInitFormParams(),
    });
    this.getMenus();
    if(permission === 'admin'){
      this.getPractitioners({date_reserved: this.state.initParams.reserved_at})
    }else{
      this.getTimeReserved(practitioner_id)
    }
  }

  validatePhoneNumber(values){
    let error;
    if (!/^[0-9]+$/i.test(values.phone_number) || values.phone_number.length !== 11) {
      error = '11ケタの数値で入力してください';
      this.setState({errorPhoneNumber: true});
    }
    else if(this.state.errorPhoneNumber) {
      this.getUserByPhoneNumber({phone_number : values.phone_number}, values);
    }
    return error;
  };



  buildInitFormParams = () => {
    const today = new Date();
    let initParams;
    if (this.props.isEdit) {
      initParams = {
        email: this.props.dataParams.email || '',
        full_name_kanji: this.props.dataParams.full_name_kanji,
        phone_number: this.props.dataParams.phone_number,
        reserved_at: this.props.dataParams.reserved_at,
        menu_ids: this.props.dataParams.menu_ids,
        practitioner_id: this.props.dataParams.practitioner_id,
        minute: this.props.dataParams.minute
      };
    } else {
      initParams = {
        email: '',
        full_name_kanji: '',
        phone_number: '',
        reserved_at: today.getFullYear() + '/' + (today.getMonth() < 9 ? '0' + (today.getMonth() + 1): (today.getMonth() + 1)) +'/'+ (today.getDate() < 10 ? ('0' + today.getDate()) : today.getDate()),
        menu_ids: [],
        practitioner_id: '',
        minute: '',
      };
    }
    return initParams;
  };

  getPractitioners = (params) => {
    this.setState(state => ({
      timeCanReserved: [],
      practitioners: [],
      pagination: 1,
    }));
    rf.get(`/admin/practitioners`, { params: params })
      .then(res => {
        this.setState(state => ({
          practitioners: res.data.practitioners,
          pagination: res.data.meta.pagination,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  };

  getUserByPhoneNumber = (params, values) =>{
    rf.get(`/admin/users/get_info_by_phone_number`, { params: params })
      .then(res=>{
        const initParams = {...values};
        const user = res.data;
        initParams.email = user.email;
        initParams.full_name_kanji = user.full_name_kanji;
        this.setState({initParams: initParams});
        this.setState({errorPhoneNumber: false});
      })
      .catch(error => console.log(error))
  }

  getMenus = () => {
    const params = {
      create_reservation: true,
    };
    rf.get(`/menus`, {params})
      .then(res => {
        this.setState(state => ({
          menus: res.data,
        }));
      })
      .catch(error => {
        console.log(error)
      });
  };

  getTimeReserved = (practitionerId, reserved_at = '') => {
    this.setState(state => ({timeCanReserved: []}));
    rf.get(`/admin/practitioners/${practitionerId}/get_shifts_can_reserved`, { params : {date_reserved: reserved_at ? reserved_at : this.state.initParams.reserved_at }})
      .then(res => {
        const timeCanReserved = res.data.shifts_can_reserved || [];
        this.setState(state => ({timeCanReserved}));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  };

  onChangeSelectDate(reserved_at){
    let newInitParams = {...this.state.initParams};
    newInitParams.reserved_at = reserved_at;
    this.setState({initParams: newInitParams});
    this.getPractitioners({date_reserved: reserved_at});
  }

  confirmation = (values) => {
    this.setState({loading: true})
    if (this.props.isEdit) {
      this.editRecord(values);
    } else {
      this.createRecord(values)
    }
  };

  formatDate = (day)=>{
    let date = new Date(day);
    return (date.getMonth() < 9 ? '0' + (date.getMonth() + 1): (date.getMonth() + 1)) +'/'+ (date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()) +'/'+ date.getFullYear();
  }

  createRecord = (params) => {
    const config = {
      headers: JSON.parse(localStorage.getItem('headers_request'))
    };
    const url = `${BASE_API_URL}/admin/reservations`;
    const formData = new FormData();

    formData.append('email', params.email);
    formData.append('full_name_kanji', params.full_name_kanji);
    formData.append('phone_number', params.phone_number);
    formData.append('reservation[minute]', params.minute);
    formData.append('reservation[reserved_at]', this.formatDate(this.state.initParams.reserved_at) + ' ' + params.reserved_time);
    formData.append('reservation[practitioner_id]', permission === 'admin' ? params.practitioner_id : practitioner_id);
    params.menu_ids.forEach(element => {
      formData.append('reservation[menu_ids][]', element)
    });
    
    post(url, formData, config)
      .then(res => {
        this.props.history.push('/reservations');
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(() => this.setState({loading: false}))

  };

  editRecord = (params) => {
    const config = {
      headers: JSON.parse(localStorage.getItem('headers_request'))
    };

    const formData = new FormData();
    formData.append('email', params.email);
    formData.append('full_name_kanji', params.full_name_kanji);
    formData.append('phone_number', params.phone_number);
    formData.append('reservation[minute]', params.minute);
    formData.append('reservation[reserved_at]', this.formatDate(this.state.initParams.reserved_at) + ' ' + params.reserved_time);
    formData.append('reservation[practitioner_id]', params.practitioner_id);
    params.menu_ids.forEach(element => {
      formData.append('reservation[menu_ids][]', element)
    });

    const url = `${BASE_API_URL}/admin/reservations/${this.props.dataParams.id}`;
    patch(url, formData, config)
      .then(res => {
        window.location.reload();
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      })
      .finally(() => this.setState({loading: false}))

  };

  onChangePractitioner = (event) => {
    const practitionerId = event.target.value;
    this.getTimeReserved(practitionerId);
  };

  changeReservedTime = (item) => {
    this.setState({
      reservedTime: item,
    });
  };

  handleChangeDate = (event) => {
    const reserved_at = event.target.value.replace(/-/g,'/');
    let newInitParams = {...this.state.initParams};
    newInitParams.reserved_at = reserved_at;
    this.setState({initParams: newInitParams});
    if(permission === 'admin'){
      this.getPractitioners({date_reserved: reserved_at});
    } else {
      this.getTimeReserved(practitioner_id, reserved_at);
    }
  }

  validatePractitionerId = (value) => {
    let error;
    if (!value) {
      error = 'This field is required';
    }
    return error;
  };

  render() {
    const {timeCanReserved} = this.state;
    return (
      <MyContent onCreate={true} onBack={true} name='reservations' header='予約一覧' contentCreate='新規予約登録'>
        <Fragment>
          <Formik
            initialValues={this.state.initParams}
            onSubmit={this.confirmation}
            validationSchema={ReservationSchema}
            enableReinitialize={true}
            dirty={true}
          >
            {({ values, errors, touched, isValidating, isValid, setFieldValue }) => (
              <Form className="formik_form">

                <div className="group_input_name clearfix">
                  <span className="title">メールアドレス {errors.email && touched.email && <span className='error'>{errors.email}</span>}</span>
                  <FastField className="input" type='text' name='email' placeholder="例）example@example.com" validate={validateEmail}/>
                </div>

                <div className="group_input_name clearfix">
                  <span className="title">お名前 {errors.full_name_kanji && touched.full_name_kanji && <span className='error'>{errors.full_name_kanji}</span>}</span>
                  <FastField className="input" type='text' name='full_name_kanji' placeholder="予約希望太郎" validate={validateFullNameKanji}/>
                </div>


                <div className="group_input_name clearfix">
                  <span className="title">携帯電話番号 {errors.phone_number && touched.phone_number && <span className='error'>{errors.phone_number}</span>}</span>
                  <FastField className="input" type='text' name='phone_number' placeholder="例）09012345678 " validate={()=>this.validatePhoneNumber(values)}/>
                </div>


                <div className="group_input_name">
                  <span className="title">お悩み {errors.menu_ids && touched.menu_ids && <span className='error'>{errors.menu_ids}</span>}</span>
                  {
                    this.state.menus && this.state.menus.map((menu, index) => (
                      <label className="item_menu" key={index} htmlFor={menu.name}>
                        <Field type="checkbox" key={index} name="menu_ids" value={menu.id.toString()} id={menu.name} validate={this.validateMenus}/>
                        <span className="name">{menu.name}</span>
                      </label>
                    ))
                  }
                </div>

                <div className="group_input_name">
                  <span className="title">施術時間を選択 {errors.minute && touched.minute && <span className='error'>{errors.minute}</span>}</span>
                  <Field as="select" name="minute">
                    <option value=""></option>
                    <option value={60}>60分</option>
                    <option value={90}>90分</option>
                  </Field>
                </div>


                <div className="group_input_name clearfix">
                  <span className="title">日付 {errors.reserved_at && touched.reserved_at && <span className='error'>{errors.reserved_at}</span>}</span>
                  <TextField
                    onChange={this.handleChangeDate}
                    className="input"
                    variant="outlined"
                    type="date"
                    defaultValue={this.state.initParams.reserved_at.replace(/\//g, '-')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>


                <div className="group_input_name">
                  {permission === 'admin' && <>
                    <span className="title">担当施術師 {errors.practitioner_id && touched.practitioner_id && <span className='error'>{errors.practitioner_id}</span>}</span>
                    <select
                      name="practitioner_id"
                      onChange={(event) => {
                        setFieldValue('practitioner_id', event.target.value);
                        this.onChangePractitioner(event);
                      }}
                      style={{width: 340}}
                    >
                      <option></option>
                      {
                        this.state.practitioners && this.state.practitioners.map((practitioner, index) => (
                          <option value={practitioner.id} key={practitioner.id}>
                            {practitioner.full_name_kanji} - {practitioner.full_name_kana} ({practitioner.email})
                          </option>
                        ))
                      }
                    </select>
                  </>}
                  {permission !== 'admin' && timeCanReserved && timeCanReserved.length === 0 && <span>You don't have shift in day</span>}
                  <div className="reserved_times">
                    {timeCanReserved && timeCanReserved.length > 0 &&
                      timeCanReserved.map((item, index) => {
                        return (
                          <span
                            key={index}
                            className={`reserved_time ` + (this.state.reservedTime === item ? 'active' : '')}
                            onClick={() => {
                              setFieldValue('reserved_time', item);
                              this.changeReservedTime(item);
                            }}
                          >
                            {item}
                          </span>
                        );
                      })
                    }
                  </div>
                  {errors.reserved_time && touched.practitioner_id && <span className='error'>{errors.reserved_time}</span>}
                </div>
                <Button variant="contained" className="button-save" disabled={this.state.loading} type="submit">保存する</Button>
              </Form>
            )}
          </Formik>
        </Fragment>
      </MyContent>
    );
  };
}

export default ReservationsCreateEdit;
