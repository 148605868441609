import rf,{ setHeader } from './requests/requests';
const authProvider = {
    login: async ({ username, password }) => {
        await rf.post(`/admin/auth/sign_in`, { email:username,  password: password })
          .then(res => {
            localStorage.setItem('practitioner_id', res.data.practitioner_id);
            localStorage.setItem('permission', res.data.role);
            localStorage.removeItem('not_authenticated');
            localStorage.setItem('headers_request', JSON.stringify(res.headers));
            window.location.reload();
          })
          .catch(error => {
            localStorage.clear();
            localStorage.setItem('not_authenticated', true);
            return Promise.reject();
          });
    },
    logout: () => {
        localStorage.clear();
        localStorage.setItem('not_authenticated', true);
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        return status === 401 || status === 403
            ? Promise.reject()
            : Promise.resolve();
    },
    checkAuth: () => {
        if(localStorage.headers_request && localStorage.permission && ((localStorage.permission === 'practitioner' && localStorage.practitioner_id) || localStorage.permission === 'admin')) {
            setHeader();
            localStorage.removeItem('not_authenticated');
        } else { 
            localStorage.setItem('not_authenticated', true);
        }
        return localStorage.getItem('not_authenticated')
            ? Promise.reject()
            : Promise.resolve();
    },
    getPermissions: () => {
        const permission = localStorage.getItem('permission');
        return Promise.resolve(permission);
    },
};

export default authProvider;