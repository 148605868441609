import CampaignsList from './CampaignsList';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import CampaignsCreateEdit from './CampaignsCreateEdit';

const permission = localStorage.getItem('permission');

const Campaigns = {
    list: CampaignsList,
    create: permission === 'admin' ? CampaignsCreateEdit : undefined,
    edit: permission === 'admin' ? CampaignsCreateEdit : undefined,
    icon: DirectionsRunIcon,
};
export default Campaigns;
