/* eslint react/jsx-key: off */
import React from 'react';
import {render} from 'react-dom';
import App from "./App";
import 'react-notifications/lib/notifications.css';
import './styles/style-common.scss';

render(
    <App/>,
    document.getElementById('root')
);
