import React from 'react'
import { Link } from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import { AppBar, Button, Typography, TableContainer, Paper} from '@material-ui/core';

const permission = localStorage.getItem('permission');
const MyContent = (props) => {
    return (
        <div className="right_content_page" id={`${props.name}_page`}>
            <AppBar color="default" style={{ marginBottom: 30, zIndex: 1 }} position="relative" className="header_right_content_page">
                <Typography variant="h6" className="title_header_right_content_page">
                    {props.header}
                </Typography>
                {
                    props.onCreate && 
                        ((permission === 'admin' || props.name === 'reservations') && 
                            <Button className="btn_create" variant="contained" component={Link} to={`/${props.name}/create`}> 
                                {props.contentCreate}
                            </Button>
                        )
                }
            </AppBar>
            {
                props.onBack && 
                <Button variant="contained" component={Link} to={`/${props.name}`}> 
                    「{props.header}」へ戻る
                </Button>
            }
            <TableContainer component={Paper}>
                {props.children}
                <NotificationContainer />
            </TableContainer>
        </div>
    )
}

export default MyContent
