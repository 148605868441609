const data = {
    shifts: [
        {
            id: 1,
            name: 'Logan Schowalter',
            role: 'admin',
        },
        {
            id: 2,
            name: 'Breanna Gibson',
            role: 'user',
        },
        {
            id: 3,
            name: 'Annamarie Mayer',
            role: 'user',
        },
    ],
};

export default data;