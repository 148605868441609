import React from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Checkbox,
  Divider
} from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import {makeStyles} from "@material-ui/core/styles";
import FilterListIcon from '@material-ui/icons/FilterList';

const permission = localStorage.getItem('permission');

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rowItem: {
    padding: 5,
  },
  rowItemTitle: {
    fontWeight: '600',
    paddingRight: 7,
  },
  iconRowItem: {
    padding: 10,
  },
}));

const TableRowLarge = (props) => {
  const {row,} = props;
  return (
    <TableRow>
      <TableCell align="left">{row.full_name_kanji}</TableCell>
      <TableCell align="left">{row.full_name_kana}</TableCell>
      {permission === 'admin' && <TableCell align="left">{row.email}</TableCell>}
      <TableCell align="left">{row.gender}</TableCell>
      {permission === 'admin' && <TableCell align="left">{row.phone_number}</TableCell>}
      <TableCell align="left">{row.subscription_type === "twice_a_month" ? "月2回サブスク" : row.subscription_type === "four_times_a_month" ? "月4回サブスク" : ""}</TableCell>
      <TableCell align="left"><Button variant="contained" component={Link} to={`/users/${row.id}/show`}><DescriptionIcon /></Button></TableCell>
    </TableRow>
  );
};

const TableRowSmall = (props) => {
  const classes = useStyles();
  const {row} = props;
  const theme = useTheme();
  const isSmallMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <TableRow>
      <TableCell align="left">
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>氏名（漢字）:</span> {row.full_name_kanji}</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>氏名（カナ）:</span> {row.full_name_kana}</div>
        {permission === 'admin' && <div className={classes.rowItem}><span className={classes.rowItemTitle}>メール:</span> {row.email}</div>}
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>性別:</span> {row.gender}</div>
        {permission === 'admin' && <div className={classes.rowItem}><span className={classes.rowItemTitle}>電話番号:</span> {row.phone_number}</div>}
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>サブスク契約:</span> {row.subscription_type === "twice_a_month" ? "月2回サブスク" : row.subscription_type === "four_times_a_month" ? "月4回サブスク" : ""}</div>
        {isSmallMobileScreen &&
          <div className={classes.rowItem}>
            <Button variant="contained" component={Link} to={`/users/${row.id}/show`}><DescriptionIcon /></Button>
          </div>
        }
      </TableCell>

      {!isSmallMobileScreen &&
        <TableCell align="left">
          <div className={classes.iconRowItem}>
            <Button variant="contained" component={Link} to={`/users/${row.id}/show`}><DescriptionIcon /></Button>
          </div>
        </TableCell>
      }
    </TableRow>
  );
};

function FilterColumns(props) {
  const defaultData = [
    {
      checked: true,
      label: 'ALL',
      subscription_type: 'all',
    },
    {
      checked: false,
      label: '月2回サブスク',
      subscription_type: 'twice_a_month',
    },
    {
      checked: false,
      label: '月4回サブスク',
      subscription_type: 'four_times_a_month',
    },
  ]
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(defaultData);
  const handleClickItem = (index) => {
    const _data = data;
    _data[index].checked = !_data[index].checked;
    setData([..._data]);
  } 

  
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  const handleReset = async () =>{
    await props.getUsers();
    setData([...defaultData]);
    setOpen(false);
  }

  const handleFilter = async () => {
    const subscription_type = data.filter(item => item.checked === true).map(item => item.subscription_type);
    const params = { subscription_type:  subscription_type.includes('all') ? undefined : subscription_type}
    await props.getUsers(params);
    setOpen(false);
  }

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <FilterListIcon/>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {data.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleClickItem(index)}>
                      <Checkbox checked={item.checked} />
                      {item.label}
                    </MenuItem>
                  ))}
                  <Divider/>
                  <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    <Button onClick={handleReset}>Reset</Button>
                    <Button onClick={handleFilter}>Ok</Button>
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>)
}

function UsersTable(props) {
  const {users, getUsers} = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Table aria-label="simple table">
      <TableHead>
        {!isMobileScreen &&
          <TableRow>
            <TableCell align="left">氏名（漢字）</TableCell>
            <TableCell align="left">氏名（カナ）</TableCell>
            {permission === 'admin' && <TableCell align="left">メール</TableCell>}
            <TableCell align="left">性別</TableCell>
            {permission === 'admin' && <TableCell align="left">電話番号</TableCell>}
            <TableCell align="left">
              サブスク契約
              <FilterColumns getUsers={getUsers}/>
            </TableCell>
            <TableCell align="left">詳細</TableCell>
          </TableRow>
        }
      </TableHead>
      <TableBody>
        {users.map((row, index) => {
          if (isMobileScreen) {
            return <TableRowSmall
              row={row}
              key={index}
            />
          }
          return (
            <TableRowLarge
              row={row}
              key={index}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default UsersTable;
