import React from 'react';
import {
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rowItem: {
    padding: 5,
  },
  rowItemTitle: {
    fontWeight: '600',
    paddingRight: 7,
  },
  iconRowItem: {
    padding: 10,
  },
}));

const TableRowLarge = (props) => {
  const {row} = props;
  return (
    <TableRow>
      <TableCell align="left"><Avatar variant="square" width="40" height="40" src={row.link_to_image} alt="" /></TableCell>
      <TableCell align="left">
        {row.full_name_kanji}
      </TableCell>
      <TableCell align="left">{row.full_name_kana}</TableCell>
      <TableCell align="left">{row.email}</TableCell>
      <TableCell align="left">{row.gender === 'female' ? '女性' : '男性'}</TableCell>
      <TableCell align="left">{row.phone_number}</TableCell>
      <TableCell align="left">{row?.belong_store?.name || ''}</TableCell>
      <TableCell align="left">{row.can_show_shift && <Button variant="contained" component={Link} to={`/practitioners/${row.id}/shifts`}><DateRangeIcon /></Button>}</TableCell>
      <TableCell align="left">{row.can_edit_practitioner && <Button variant="contained" component={Link} to={`/practitioners/${row.id}`}><EditIcon /></Button>}</TableCell>
      <TableCell align="left"><Button variant="contained" component={Link} to={`/practitioners/${row.id}/show`}><DescriptionIcon /></Button></TableCell>
    </TableRow>
  );
};

const TableRowSmall = (props) => {
  const classes = useStyles();
  const {row} = props;
  const theme = useTheme();
  const isSmallMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <TableRow>
      <TableCell align="left">
        <div className={classes.rowItem}>
          <Avatar variant="square" src={row.link_to_image} alt="" className={classes.large} />
        </div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>氏名（漢字）:</span> {row.full_name_kanji}</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>氏名（カナ）:</span> {row.full_name_kana}</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>メール:</span> {row.email}</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>性別:</span> {row.gender === 'female' ? '女性' : '男性'}</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>電話番号:</span> {row.phone_number}</div>
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>所属欄:</span> {row?.belong_store?.name || ''}</div>

        {isSmallMobileScreen &&
          <div className={classes.rowItem}>
            {row.can_show_shift && <Button variant="contained" component={Link} to={`/practitioners/${row.id}/shifts`} style={{marginRight: 15}}><DateRangeIcon /></Button>}
            {row.can_edit_practitioner && <Button variant="contained" component={Link} to={`/practitioners/${row.id}`}><EditIcon /></Button>}
            <Button variant="contained" component={Link} to={`/practitioners/${row.id}/show`}><DescriptionIcon /></Button>
          </div>
        }
      </TableCell>

      {!isSmallMobileScreen &&
        <TableCell align="left">
          <div className={classes.iconRowItem}>
            {row.can_show_shift && <Button variant="contained" component={Link} to={`/practitioners/${row.id}/shifts`}><DateRangeIcon /></Button>}
          </div>
          <div className={classes.iconRowItem}>
            {row.can_edit_practitioner && <Button variant="contained" component={Link} to={`/practitioners/${row.id}`}><EditIcon /></Button>}
          </div>
          <div className={classes.iconRowItem}>
            <Button variant="contained" component={Link} to={`/practitioners/${row.id}/show`}><DescriptionIcon /></Button>
          </div>
        </TableCell>
      }
    </TableRow>
  );
};

function PractitionersTable(props) {
  const {practitioners} = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Table aria-label="simple table">
      <TableHead>
        {!isMobileScreen &&
          <TableRow>
            <TableCell align="left">写真</TableCell>
            <TableCell align="left" className="">氏名（漢字）</TableCell>
            <TableCell align="left" className="">氏名（カナ）</TableCell>
            <TableCell align="left" className="">メール</TableCell>
            <TableCell align="left">性別</TableCell>
            <TableCell align="left" className="">電話番号</TableCell>
            <TableCell align="left" className="">所属欄</TableCell>
            <TableCell align="left" className="">シフトを登録する</TableCell>
            <TableCell align="left">編集する</TableCell>
            <TableCell align="left">詳細</TableCell>
          </TableRow>
        }
      </TableHead>
      <TableBody>
        {practitioners.map((row, index) => {
          if (isMobileScreen) {
            return <TableRowSmall
              row={row}
              key={index}
            />
          }
          return (
            <TableRowLarge
              row={row}
              key={index}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default PractitionersTable;
