import {ActionCableConsumer} from "react-actioncable-provider";
import React from "react";

const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT
const CLIENT_SHIFT_CAN_RESERVED_CHANNEL = 'ClientShiftCanReservedChannel';

const listenReceiverOrder = (props) => {
  return (
    <ActionCableConsumer
      channel={{channel: 'ClientShiftCanReservedChannel'}}
      onReceived={props.onReceived}
    />
  );
};

export {
  SOCKET_ENDPOINT,
  CLIENT_SHIFT_CAN_RESERVED_CHANNEL,
  listenReceiverOrder,
};
