import React, { Component } from "react";
import { Route } from 'react-router-dom';
import { Admin, Resource } from "react-admin"; // eslint-disable-line import/no-unresolved
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import i18nProvider from "./i18nProvider";
import MainLayout from "./layout/Layout";
import Practitioners from "./features/practitioners";
import Reservations from "./features/reservations";
import Reviews from "./features/reviews";
import Campaigns from "./features/campaigns";
import Users from "./features/users";
import Prices from "./features/prices";
import Promotions from "./features/promotions";
import SettingCoupons from "./features/settingCoupon";
import CalendarCreate from './features/practitioners/CalendarCreate';
import PromotionsCreateEdit from './features/promotions/PromotionsCreateEdit';

const permission = localStorage.getItem('permission');

export default class App extends Component {
  render() {
    return (
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        title="Admin"
        layout={MainLayout}
        customRoutes={[
          <Route
            exact
            path="/practitioners/:id/shifts"
            component={(props) => <CalendarCreate {...props} />}
          />
        ]}
      >
        {(permissions) => [
          <Resource
            name="practitioners"
            options={{ label: "施術者一覧" }}
            {...Practitioners}
          />,
          <Resource
            name="users"
            options={{ label: "ユーザー一覧" }}
            {...Users}
          />,
          <Resource
            name="reservations"
            options={{ label: "予約一覧" }}
            {...Reservations}
          />,
          <Resource
            name="reviews"
            options={{ label: "レビュー" }}
            {...Reviews}
          />,
          <Resource
            name="campaigns"
            options={{ label: "キャンペーン" }}
            {...Campaigns}
          />,
          <Resource
            name="admin_promotions"
            options={{ label: <div>プロモーションコード<br/>一覧</div> }}
            {...Promotions}
            create={permission === 'admin' ? PromotionsCreateEdit : undefined}
          />,
          <Resource
            name="invitation_coupons"
            options={{ label: <div>招待クーポン<br/>利用状況</div> }}
            {...Promotions}
          />,
          <Resource
            name="contributed_coupons"
            options={{ label: <div>招待貢献クーポン<br/>利用状況</div> }}
            {...Promotions}
          />,
          <Resource
            name="invitation_default_coupons"
            options={{ label: <div>招待クーポン<br/>管理者設定</div> }}
            {...SettingCoupons}
          />,
          <Resource
            name="contributed_default_coupons"
            options={{ label: <div>招待貢献クーポン<br/>管理者設定</div> }}
            {...SettingCoupons}
          />,
          <Resource
            name="prices"
            options={{ label: "価格一覧" }}
            {...Prices}
          />,
        ]}
      </Admin>
    );
  }
}
