import React, {useCallback, useState} from 'react';
import {Avatar, Table, TableBody, TableCell, TableHead, TableRow, Button, useMediaQuery, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText} from "@material-ui/core";
import { Formik, Form, Field } from 'formik';
import {makeStyles} from "@material-ui/core/styles";
// import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

const permission = localStorage.getItem('permission');

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rowItem: {
    padding: 5,
  },
  rowItemTitle: {
    fontWeight: '600',
    paddingRight: 7,
  },
  iconRowItem: {
    padding: 10,
  },
}));

const MemoCreateEdit = (props) => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    await props.onUpdateMemo(props.id, values.memo);
    handleClose();
  }

  const validateNote = (value) => {
    let error;
    error = value.length > 1000 ? 'メモは、最大1000文字です。' : '';
    return error;
  }

  const handleClose = () =>{
    setOpen(false);
    setIsEdit(false);
    setLoading(false);
  }

  const handEdit = () => {
    setOpen(true);
    setIsEdit(true);
  }

  return (
    <div className='memo'>
      {props.memo && <div className='block-ellipsis'>{props.memo}</div>}
      {!open && (
        <div className='memo-group-button'>
          { props.memo && 
            <Button variant="contained" onClick={() => setOpen(true)}>詳細</Button>
          }
          <Button variant="contained" onClick={handEdit}>
            {props.memo ? "編集" : "作成"}
          </Button>
        </div>
      )}
      <Dialog open={open} className="dialog_confirm" onClose={handleClose}>
        <DialogContent>
          {isEdit ? (
            <Formik
              initialValues={{ memo: props.memo || "" }}
              onSubmit={handleSubmit}
            >
              {({errors}) => (
                <Form>
                  <div>{errors.memo && <span style={{color: '#ff0000', fontSize: 12}}>{errors.memo}</span>}</div>
                  <Field
                    rows="10"
                    style={{ width: 500 }}
                    name="memo"
                    validate={validateNote}
                    component="textarea"
                    placeholder="メモ"
                  />
                  <DialogActions  className="group_botton">
                    <Button color="primary" variant="outlined" onClick={handleClose}>
                      キャンセル
                    </Button>
                    {loading ?
                      <Button color="secondary" variant="outlined"><CircularProgress className="icon_loadding" color={'inherit'} size={20}/></Button>
                      :
                      <Button color="secondary" variant="outlined" type="submit">
                        保存
                      </Button>
                    }
                  </DialogActions>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <DialogTitle style={{textAlign: 'center'}}>メモ</DialogTitle>
              <DialogContentText style={{whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                {props.memo}
              </DialogContentText>
              <DialogActions className="group_botton">
                <Button color="primary" variant="outlined" onClick={handleClose}>
                  閉じる
                </Button>
                <Button color="secondary" variant="outlined" onClick={handEdit}>
                  編集
                </Button>
              </DialogActions>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const TableRowLarge = (props) => {
  const {row, changeReservationsStatus, onUpdateMemo, deleteReservation} = props;
  return (
    <TableRow>
      <TableCell align="left">{row.menus.map(menu=>menu.name).toString()}</TableCell>
      <TableCell align="left">{row.start_at}</TableCell>
      <TableCell align="left">{row.end_at}</TableCell>
      <TableCell align="left">{row.payments_status.id === 4 ? '月2回サブスク' : row.payments_status.id === 5 ? '月4回サブスク' : row.payments_status.status}</TableCell>
      <TableCell align="left">{parseInt(row.price)}円 (税込)</TableCell>
      <TableCell align="left">
        <ul className="list_practitioner_info">
          <li>
            <Avatar variant="square" width="40" height="40" src={row.practitioner?.link_to_image} alt="" />
          </li>
          <li>
            - 氏名（漢字）: {row.practitioner && row.practitioner.full_name_kanji}
          </li>
          <li>
            - 氏名（カナ）: {row.practitioner && row.practitioner.full_name_kana}
          </li>
          <li>
            - メール : {row.practitioner && row.practitioner.email}
          </li>
          <li>
            - 電話番号: {row.practitioner && row.practitioner.phone_number}
          </li>
        </ul>
      </TableCell>
      <TableCell align="left">
        {window._.isEmpty(row.promotion) ? "なし" : <ul className="list_practitioner_info">
          <li>
            - クーポン種類: {row.promotion.type === "AdminPromotion" ? "運営発行のプロモーションコード" : row.promotion.type === "InvitationCoupon" ? "招待クーポンコード" : "招待貢献クーポンコード"}
          </li>
          <li>
            {row.promotion.type === "AdminPromotion" ? "" : row.promotion.type === "InvitationCoupon" ? row.promotion.user_invite.full_name_kanji : row.promotion.new_user.full_name_kanji}
          </li>
          <li>
            - クーポン名: {row.promotion.title || row.promotion.discount_code}
          </li>
          <li>
            - 割引額 : {row.promotion.discount_rate}%OFF
          </li>
        </ul>}
      </TableCell>
      <TableCell align="left">
        {(permission === 'admin' || row.can_edit_reservation) && 
          <select as="select"
                  name="reservations_status"
                  className={row.reservations_status && row.reservations_status.id === 1 ? 'under_reservation' : (row.reservations_status && row.reservations_status.id === 2 ? 'treatment_complete' : 'cancel_reservation')}
                  onChange={(event) => changeReservationsStatus(event, row)} value={row.reservations_status && row.reservations_status.id}
                  >
            <option value="1">予約中</option>
            <option value="2">施術完了</option>
            <option value="3">予約キャンセル</option>
          </select>
        }
      </TableCell>
      <TableCell align="left">
      {(permission === 'admin' || row.can_update_memo) && 
        <MemoCreateEdit memo={row.memo} id={row.id} onUpdateMemo={onUpdateMemo}/>
      }
      </TableCell>
      {/* {permission === 'admin' && 
        <TableCell align="center">
          <Button variant="contained" onClick={() => deleteReservation(row.id)}><DeleteIcon /></Button>
        </TableCell>
      } */}
    </TableRow>
  );
};

const TableRowSmall = (props) => {
  const {row, changeReservationsStatus, onUpdateMemo, deleteReservation} = props;
  const classes = useStyles()
  const theme = useTheme();
  const isSmallMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const practitionerInfo = useCallback(() => {
    return (
      <div className={classes.rowItem}>
        <span className={classes.rowItemTitle}>施術者: </span>
        <ul className="list_practitioner_info">
          <li>
            <Avatar variant="square" src={row.practitioner?.link_to_image} alt="" className={classes.large} />
          </li>
          <li>
            - 氏名（漢字）: {row.practitioner && row.practitioner.full_name_kanji}
          </li>
          <li>
            - 氏名（カナ）: {row.practitioner && row.practitioner.full_name_kana}
          </li>
          <li>
            - メール : {row.practitioner && row.practitioner.email}
          </li>
          <li>
            - 電話番号: {row.practitioner && row.practitioner.phone_number}
          </li>
        </ul>
        <span className={classes.rowItemTitle}>クーポン使用: </span>
        {window._.isEmpty(row.promotion) ? "なし" : <ul className="list_practitioner_info">
          <li>
            - クーポン種類: {row.promotion.type === "AdminPromotion" ? "運営発行のプロモーションコード" : row.promotion.type === "InvitationCoupon" ? "招待クーポンコード" : "招待貢献クーポンコード"}
          </li>
          <li>
            {row.promotion.type === "AdminPromotion" ? "" : row.promotion.type === "InvitationCoupon" ? row.promotion.user_invite.full_name_kanji : row.promotion.new_user.full_name_kanji}
          </li>
          <li>
            - クーポン名: {row.promotion.title || row.promotion.discount_code}
          </li>
          <li>
            - 割引額 : {row.promotion.discount_rate}%OFF
          </li>
        </ul>}
      </div>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  return (
    <TableRow>
      <TableCell align="left">
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>悩み: </span>
          {row.menus.map(menu=>menu.name).toString()}
        </div>

        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>開始時間: </span>
          {row.start_at}
        </div>
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>終了時間: </span>
          {row.end_at}
        </div>
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>決済方法: </span>
          {row.payments_status.id === 4 ? '月2回サブスク' : row.payments_status.id === 5 ? '月4回サブスク' : row.payments_status.status}
        </div>
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>価格: </span>
          {parseInt(row.price)}円 (税込)
        </div>
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>ステータス: </span>
          {(permission === 'admin' || row.can_edit_reservation) &&
            <select
              as="select"
              name="reservations_status"
              className={row.reservations_status && row.reservations_status.id === 1 ? 'under_reservation' : (row.reservations_status && row.reservations_status.id === 2 ? 'treatment_complete' : 'cancel_reservation')}
              onChange={(event) => changeReservationsStatus(event, row)} value={row.reservations_status && row.reservations_status.id}
            >
              <option value="1">予約中</option>
              <option value="2">施術完了</option>
              <option value="3">予約キャンセル</option>
            </select>
          }
        </div>
        <div className={classes.rowItem}>
          <span className={classes.rowItemTitle}>メモ: </span>
          {(permission === 'admin' || row.can_update_memo) && 
            <MemoCreateEdit memo={row.memo} id={row.id} onUpdateMemo={onUpdateMemo}/>
          }
        </div>
        {/* {permission === 'admin' &&
          <div style={{textAlign: 'center'}}>
            <Button variant="contained" onClick={() => deleteReservation(row.id)}><DeleteIcon /></Button>
          </div>
        } */}

        {isSmallMobileScreen && practitionerInfo()}

      </TableCell>

      {!isSmallMobileScreen &&
        <TableCell align="left">
          {practitionerInfo()}
        </TableCell>
      }
    </TableRow>
  );
};

function UserReservationsTable(props) {
  const {reservations, changeReservationsStatus, onUpdateMemo, deleteReservation} = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Table aria-label="simple table">
      <TableHead>
        {!isMobileScreen &&
          <TableRow>
            <TableCell align="left" className="min_100">悩み</TableCell>
            <TableCell align="left" className="min_100">開始時間</TableCell>
            <TableCell align="left" className="min_100">終了時間</TableCell>
            <TableCell align="left">決済方法</TableCell>
            <TableCell align="left">価格</TableCell>
            <TableCell align="left">施術者</TableCell>
            <TableCell align="left">クーポン使用</TableCell>
            <TableCell align="left">ステータス</TableCell>
            <TableCell align="left">メモ</TableCell>
            {/* {permission === 'admin' && <TableCell align="left">削除</TableCell>} */}
          </TableRow>
        }
      </TableHead>
      <TableBody>
        {reservations.map((row, index) => {
          if (isMobileScreen) {
            return (
              <TableRowSmall
                key={index}
                changeReservationsStatus={changeReservationsStatus}
                row={row}
                onUpdateMemo={onUpdateMemo}
                deleteReservation={deleteReservation}
              />
            );
          }

          return (
            <TableRowLarge
              key={index}
              changeReservationsStatus={changeReservationsStatus}
              row={row}
              onUpdateMemo={onUpdateMemo}
              deleteReservation={deleteReservation}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default UserReservationsTable;
