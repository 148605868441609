import React from 'react';
import { Link } from 'react-router-dom';
import {Button, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from "@material-ui/core/styles";

const permission = localStorage.getItem('permission');

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rowItem: {
    padding: 5,
  },
  rowItemTitle: {
    fontWeight: '600',
    paddingRight: 7,
  },
  iconRowItem: {
    padding: 10,
  },
  ratingColumn: {
    position: 'relative',
  },
  [theme.breakpoints.down("md")]: {
    ratingColumn: {
      position: 'relative',
      display: 'inline-block',
    },
  },
  ratingNumber: {
  },
  ratingControl: {
    float: 'left',
    marginRight: 5,
  },
}));

const TableRowLarge = (props) => {
  const {row} = props;
  return (
    <TableRow>
      <TableCell align="left">{parseInt(row.price)}円 (税込)</TableCell>
      {permission === 'admin' && <TableCell align="left"><Button variant="contained" component={Link} to={`/prices/${row.id}`}><EditIcon /></Button></TableCell>}
    </TableRow>
  );
};

const TableRowSmall = (props) => {
  const classes = useStyles();
  const {row} = props;
  const theme = useTheme();
  const isSmallMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <TableRow>
      <TableCell align="left">
        <div className={classes.rowItem}><span className={classes.rowItemTitle}>価格:</span> {parseInt(row.price)}円 (税込)</div>

        {isSmallMobileScreen && permission === 'admin' &&
          <div className={classes.rowItem}>
            <Button variant="contained" component={Link} to={`/prices/${row.id}`}><EditIcon /></Button>
          </div>
        }
      </TableCell>

      {!isSmallMobileScreen && permission === 'admin' &&
        <TableCell align="left">
          <div className={classes.iconRowItem}>
            <Button variant="contained" component={Link} to={`/prices/${row.id}`}><EditIcon /></Button>
          </div>
        </TableCell>
      }
    </TableRow>
  );
};

function PricesTable(props) {
  const {data, onShowEditRecord} = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Table aria-label="simple table">
      <TableHead>
        {!isMobileScreen &&
          <TableRow>
            <TableCell align="left">価格</TableCell>
            {permission === 'admin' && <TableCell align="left">編集する</TableCell>}
          </TableRow>
        }
      </TableHead>
      <TableBody>
        {data.map((row, index) => {
          if (isMobileScreen) {
            return <TableRowSmall row={row} key={index} onShowEditRecord={onShowEditRecord} />
          }
          return (
            <TableRowLarge row={row} key={index} onShowEditRecord={onShowEditRecord} />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default PricesTable;
