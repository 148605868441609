import {validEmailRegex} from "./index";

export const validateEmail = (value) => {
  let error;
  error = validEmailRegex.test(value)
    ? (value.length > 225 ? '255文字以内で入力してください' : '')
    : 'メールアドレス形式で入力してください';
  return error;
};

export const validateFullNameKanji = (value) => {
  let error;
  if (!/^([\u3041-\u3096]|[ ]|[\u30A1-\u30FA]|[一ー-龥]|[a-zA-Z])+$/i.test(value) || value.length > 128) {
    error = '「漢字・カタカナ・ひらがな」のみ入力可能です';
  }
  return error;
};

export const validatePhoneNumber = (value) => {
  let error;
  if (!/^[0-9]+$/i.test(value) || value.length !== 11) {
    error = '11ケタの数値で入力してください';
  }
  return error;
};

export const validatePractitionerId = (value) => {
  let error;
  if (!value) {
    error = 'This field is required';
  }
  return error;
};

export const validateRankId = (value) => {
  let error;
  if (!value) {
    error = '担当施術師で選ぶ';
  }
  return error;
};

