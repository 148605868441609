import React, {Component, Fragment} from 'react';
import MyContent from '../../layout/MyContent';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import rf from '../../requests/requests';
import createNotification from '../../common/Notifications';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PricesTable from "./PricesTable";
import Box from '@material-ui/core/Box';
import './style.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

class PricesList extends Component {
   constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        page: null,
        total: null,
      },
      page: 1,
      tab: 0,
      recordId: null,
      dataParams: {},
    };
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  componentDidMount() {
    this.getDataList({minute: 60});
  }

  getDataList = (params) => {
    rf.get(`/admin/prices`, { params: params })
      .then(res => {
        this.setState(state => ({
          data: res.data.prices,
          pagination: res.data.meta.pagination,
        }));
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.errors[0]&& error.response.data.errors[0].message;
        createNotification(message);
      });
  };

  pagination = () => {
    const total = this.state.pagination.total;
    if(total >= 10) {
      return (
        <div style={{margin: '30px 0px', textAlign: 'center'}}>
          <Pagination className="pagination" count={Math.ceil(total / 10)} page={this.state.page} onChange={this.handleChangePagination} variant="outlined" shape="rounded" />
          <Typography style={{marginTop: '16px'}} className="page_of">{this.state.page} /  {(total / 10) < 1 ? '1' : Math.ceil(total / 10)}</Typography>
        </div>
      )
    }
  };

  handleChangeTab(event, newValue){
    this.setState({tab: newValue, page: 1});
    this.getDataList({minute: newValue ? 90 : 60});
  }

  handleChangePagination = (event, value) => {
    this.setState({ page: value});
    const params = {
      page: value,
      minute: this.state.tab ? 90 : 60,
    };
    this.getDataList(params)
  };

  render() {
    return (
      <MyContent onCreate={true} name='prices' header='価格一覧' contentCreate='価格を登録する'>
        <Fragment>
          <Tabs value={this.state.tab} onChange={this.handleChangeTab}>
            <Tab label="60分" />
            <Tab label="90分" />
          </Tabs>
          <Divider />
          <TabPanel value={this.state.tab} index={0}>
            <>
              <PricesTable data={this.state.data}/>
              {this.pagination()}
            </>
          </TabPanel>
          <TabPanel value={this.state.tab} index={1}>
            <>
              <PricesTable data={this.state.data}/>
              {this.pagination()}
            </>
          </TabPanel>
          
        </Fragment>
      </MyContent>
    );
  };
}

export default PricesList;
