import PeopleIcon from '@material-ui/icons/People';
import PractitionersList from './PractitionersList';
import PractitionersCreate from './PractitionersCreate';
import PractitionersEdit from './PractitionersEdit';
import PractitionerDetail from './PractitionerDetail';

const permission = localStorage.getItem('permission');

const Practitioners = {
    list: PractitionersList,
    show: PractitionerDetail,
    create: permission === 'admin' ? PractitionersCreate : PractitionersList,
    edit: PractitionersEdit,
    icon: PeopleIcon,
};
export default Practitioners;
