import ReviewsList from './ReviewsList';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ReviewsEdit from './ReviewsEdit';

const permission = localStorage.getItem('permission');

const Reviews = {
    list: ReviewsList,
    edit:  permission === 'admin' ? ReviewsEdit : undefined,
    icon: RateReviewIcon,
};
export default Reviews;
